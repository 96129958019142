import {useEffect,useState} from "react";
 
 import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container ,useTheme,Box,Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet  } from 'react-helmet-async';
import {Loading } from "./loading";
import {withStyles} from '@material-ui/core/styles';

const axios = require('axios').default;   

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFBF00"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFBF00"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFBF00"
    },
    "& .MuiFormLabel-root":{
      color:"#FFBF00"
    },
     
    "& .MuiInputLabel-shrink":{
      
      transform: "translate(14px, -9px) scale(0.90)",
      transformOrigin: "top left",
      backgroundColor:"black",
      paddingLeft:"1%",
      paddingRight:"1%",
    } 
     
  },
  cardshadow: {
    "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
     
  },
});

const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";

export const Privacy=()=>{
  const classes = useStyles();
  const theme = useTheme();
  const [metadata,setMetadata] = useState();

  const [title,setTitle] = useState();
  const [loading,setLoading] = useState(true);
  useEffect(()=>{
    window.scrollTo(0, 0);
    getMeta()
  },[])


  const getMeta=()=>{
    const formData= new FormData();
    formData.append('page','privacy');
    
     
    axios.post("/web/get_single_metadata",formData ).then(  (res) => {
           
            if(res.data.status ){
              setTitle(res.data.data.seo_title)
              setLoading(false)
            }
          });     
  }

return  (  <div>{title && <Helmet>
  <title>{title}</title>
     
 </Helmet>}<Container maxWidth="md">
 {loading?<Loading/>:<Box boxShadow={3} p={3} className={classes.cardshadow}    style={{ border:"2px groove #bfaa8d",backgroundColor:"white",marginTop:"5%",marginBottom:"5%",borderRadius:"10px",backgroundImage:"url('/static/backgrounds/profilebg.jpg')" }}> 
<Typography variant="h4" className="text-center"   >
                                     Privacy Policy          
                        </Typography>
                        <hr  />
                        <br/>
                        <Grid
                          container
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          spacing={3}
                        >
                          <Grid item xs={12}>
                          <Typography variant="body1"   gutterBottom  >
                            <b>&nbsp;&nbsp;&nbsp;&nbsp;Your privacy is important to us, and so is being transparent about how we collect, use, and share information about you. This policy is intended to help you understand;</b>

                            </Typography>
                           <ol>
                             <li style={{marginTop:"1%"}}>
                             <Typography variant="body1"     >
                             <b>What information do we collect from you?</b><br/>
                             <u>At the time of Registration:</u> name, mobile and email.<br/>
                             <u>While Adding a Profile:</u> basic information required for a Matrimonial Portal such as name, age, subcaste, profile's contact details, marital status, astroglial details, profession, country, education details, family details, work details, candidate's photos, and any Verification doc like aadhar or pan or driving license, etc to verify the Profile before making it active for all users.<br/>
                             </Typography>
                             </li>
                             <li style={{marginTop:"1%"}}>
                             <Typography variant="body1"     >
                             <b>How do we use the information that we collect from you?</b>
                             <br/>
                              &nbsp;a. Validating user's Registration.<br/>
                              &nbsp;b. Account Management.<br/>
                              &nbsp;c. Application Functionality like interaction between the profiles with Email.<br/>
                              &nbsp;d. Recommending relevant profiles.<br/>
                              </Typography>
                             </li>
                             <li style={{marginTop:"1%"}}>
                             <Typography variant="body1"     >
                             <b>How do we secure your information?</b><br/>
                             &nbsp;&nbsp;&nbsp;The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
                               </Typography>
                             </li>
                              
                             <li style={{marginTop:"1%"}}>
                             <Typography variant="body1"     >
                             <b>How will I know of changes in the privacy policy?</b><br/>
                             &nbsp;&nbsp;&nbsp;If we make a material change to our privacy policy, we will update in our Privacy Policy.
                              </Typography>
                             </li>
                             
                             <li style={{marginTop:"1%"}}>
                             <Typography variant="body1"     >
                             <b>How to reach us?</b><br/>
                             &nbsp;&nbsp;&nbsp;If you have any questions about this privacy statement, please feel free to reach us from the <a href="https://www.kapukalyanalu.com/contact-us">Contact us</a> link or at <b>support@kapukalyanalu.com, kapukalyanalu@gmail.com</b>. 
                              </Typography>
                             </li>
                            
                              
                            
                           </ol>
                          </Grid>
                           
 
                          <Grid item xs={12}>
                          <Typography variant="h5" className="text-center"   style={{color:theme.palette.primary.main}}>
                              ****
                            </Typography>
                          </Grid>

                        </Grid>
  </Box>}
  </Container>
      
     
</div> );
}

 

 

 

 