import {useState,useEffect,useContext} from 'react';
import {Grid,Container,Box,Button,Switch, makeStyles,Avatar,InputLabel,Radio,RadioGroup,Select,MenuItem,FormControlLabel,FormLabel, DialogContentText,DialogTitle,Snackbar,TextField,useTheme, Typography} from '@material-ui/core';
import {UserContext,SnackbarContext} from './UserContext';
import {BackDropLoad} from './BackdropLoading';
import {Loading } from "./loading";
import {useNavigate,useParams,Link } from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
const headKey="asda()*^%~`)sdawqe23123>;p988u12hmmasd";
const axios = require('axios').default;


const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "& .MuiFormLabel-root":{
        color:"primary"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#ffffff",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });



export const MyReferredProfiles = ()=>{
    let {id} = useParams();
    let history = useNavigate ();
    const theme = useTheme();
      const classes = useStyles();
    const {user, setUser} = useContext(UserContext);
    const [data,setData] = useState([]);
    const {snack, setSnack} = useContext(SnackbarContext);

    const [isloading,setIsloading] = useState(true);

     

    const [err,setErr] = useState(0);
    const [wait,setWait] = useState(false);

      useEffect(()=>{
        window.scrollTo(0, 0);
         getData();
      },[])


      const getData=()=>{
        axios.post("/web/get_all_refer_profiles" ).then((res)=>{
                 
            if(res.data.status===1){
                 
               setData(res.data.data);
               setIsloading(false);
            }else if(res.data.status===3){
                setSnack({
                  message: res.data.msg,
                  type: 'warning',
                  open: true,
                  direction:"left"
                });
                setUser(null)
                history("/")
            }
          });
      }
      

      
     
     
     

     

    return (<div>
         <Helmet>
         <title>Suggested Profiles | Kapu Kalyanalu</title>
            
         </Helmet>
        
                <BackDropLoad wait={wait}/>
                 
       {isloading?<Loading/>:( <Box  boxShadow={3} p={3}   style={{border:"2px solid #bfaa8d",borderRadius:"5px",backgroundColor:"white",marginBottom:"10%"}}>
        
        
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={4}
            
        >
            <Grid item xs={12}> 
                <h3  className='text-center'> Suggested Profiles </h3>
                <hr/>
            </Grid>
            <Grid item xs={12} className="text-center">
            <Typography variant="body1"> Below are some of the Profiles Suggested by the <b>"Kapu Kalyanalu"</b> Support Team...</Typography>
            </Grid>
            <Grid item xs={12}> 
             
            <table id="table_id" className="table table-striped">
                <thead>
                        <tr>
                        <th className="text-center">No</th>
                        <th className="text-center">For Profile</th>
                        <th className="text-center">Suggested Profile</th>       
                        <th className="text-center">Suggested Date</th>
                            
                        

                        </tr>
                    </thead>
                    <tbody>
                     
                     
                        {data.length===0 &&<tr><td colSpan="4"><p className="text-center">No Profiles Suggested so far....</p></td></tr>}
                        {data.map((da,i)=>{
                            return (<tr>
                                <td>{data.length-i}</td>
                                <td className="text-center">
                                    <img src={da.pdpic} className="img-thumbnail"  style={{height:"200px" }}/>
                                     
                                    <br/>
                                    Name: {da.pname} {da.surname}
                                    <br/>
                                    Gender: {da.pgender}
                                    <br/>
                                    Status: {da.pstatus===1?<span style={{color:"green"}}>Active</span>:<span style={{color:"red"}}>Waiting for Approval</span>}
                                </td>
                                <td className="text-center">
                                    <img src={da.rdpic} className="img-thumbnail"  style={{height:"200px" }}/>
                                     
                                    <br/>
                                    Name: {da.rname} {da.rsurname}
                                    <br/>
                                    Gender: {da.rgender}
                                    <br/>
                                    <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/profile/"+da.rid} className="linkstyle"  style={{width:"60%"}}>View Profile</Button>
                                </td>
                                <td className="text-center">{da.ref_date}</td> 
                                    
                                
                            </tr>);
                        })}
                    </tbody>
                     
            </table>
            {  /*  <table id="table_id" className="table table-striped">
                <thead>
                        <tr>
                        <th>ID</th>
                        <th>PROFILE</th>
                        <th>REFERRED PROFILE</th>       
                        <th>REFERRED DATE</th>
                            
                        <th>STATUS</th>

                        </tr>
                    </thead>
                    <tbody>
                    {data.map((da)=>{
                            return (<tr>
                                <td>{da.id}</td>
                                <td>
                                    <Avatar alt={da.pdpic} src={da.pdpic}  style={{height:"100px",width:"100px"}}/>  
                                    <br/>
                                    Name: {da.pname} {da.surname}
                                    <br/>
                                    Gender: {da.pgender}
                                </td>
                                <td>
                                    <Avatar alt={da.rdpic} src={da.rdpic}  style={{height:"100px",width:"100px"}}/> 
                                    <br/>
                                    Name: {da.rname} {da.rsurname}
                                    <br/>
                                    Gender: {da.rgender}
                                </td>
                                <td>{da.ref_date}</td> 
                                    
                                <td  > </td> 
                            </tr>);
                        })}
                    </tbody>
                    <tfoot>
                    <tr>
                        <th>ID</th>
                        <th>PROFILE</th>
                        <th>REFERRED PROFILE</th>       
                        <th>REFERRED DATE</th>
                            
                        <th>STATUS</th>
                        </tr>
                    </tfoot>
                    </table>*/}
            </Grid>
             
         </Grid>
         
          
        </Box>)}
        
         
    </div>);
}