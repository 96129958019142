import {Grid,Container,Box,TextField,Button,useTheme,Typography,makeStyles,FormControl,Label,Select,MenuItem,InputLabel} from '@material-ui/core';
import {useState,useContext,useEffect} from 'react';
import { UserContext,SnackbarContext } from './UserContext';
import {BackDropLoad} from "./BackdropLoading";
import {Loading } from "./loading";
 
import { Helmet  } from 'react-helmet-async';

const axios = require('axios').default;   
const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000000"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000000"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000000"
      },
      "& .MuiFormLabel-root":{
        color:"#000000"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#ffffff",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });


const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";


export const ContactNow=()=>{

    const theme = useTheme();
    const classes = useStyles();

    const [title,setTitle] = useState();


    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [mob,setMob] = useState("");
    const [sub,setSub] = useState("0");
    const [msg,setMsg] = useState("");
    const [wait,setWait] = useState(false);


    const [loc,setLoc]=useState('');

    const [error,setError] = useState(0);

    const [metadata,setMetadata] = useState();

    const {snack, setSnack} = useContext(SnackbarContext);

    const [loading,setLoading] = useState(true);

    useEffect(()=>{
      window.scrollTo(0, 0);
      getMeta();
    },[])


    const getMeta=()=>{
      const formData= new FormData();
      formData.append('page','contact');
      
       
      axios.post("/web/get_single_metadata",formData ).then(  (res) => {
             
              if(res.data.status ){
                setTitle(res.data.data.seo_title)
                setLoading(false)
              }
            });     
    }

    const contactNow=(e)=>{
        e.preventDefault();
        setError(0);
        if(name===''){
            setError(1);
            setSnack({
                message: "Please Enter your name..",
                type: 'error',
                open: true,
                direction:"center"
              });
        }else if(mob.length!==10 ){
            setError(2);
            setSnack({
                message: "Please Enter a Valid 10-digit Mobile number..",
                type: 'error',
                open: true,
                direction:"center"
              });
        } else if(sub==='0' ){
            setSnack({
                message: "Please select your Subject..",
                type: 'error',
                open: true,
                direction:"center"
              });
            setError(3);
        }else if(msg===''){
            setSnack({
                message: "Please Enter your Message..",
                type: 'error',
                open: true,
                direction:"center"
              });
            setError(4);
        }else{
            setWait(true);
            
            const formData= new FormData();
            formData.append('name',name);
            formData.append('email',email);
            formData.append('mobile',mob);
            formData.append('subj',sub);
            formData.append('msg',msg);
             
            axios.post("/web/contact_now",formData ).then(  (res) => {
                   
                    if(res.data.status ){
                      setWait(false);
                       
                      setSnack({
                        message: res.data.msg,
                        type: 'success',
                        open: true,
                        direction:"center"
                      });
                      
                        
  
                         
                    }else  {
                      setWait(false);
                       
                      setSnack({
                        message: res.data.msg,
                        type: 'error',
                        open: true,
                        direction:"center"
                      });
                    }
                    
                  
               }); 
        }
    }


    

    return (<Container maxWidth="lg" > 
    {title && <Helmet>
  <title>{title}</title>
     
 </Helmet>}
 {loading?<Loading/>:    <Box m={4} >
            <form onSubmit={contactNow}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center" 
                spacing={1}
                >
                <Grid item xs={12}>
                <Typography variant="h4" className="text-center" gutterBottom>
                               CONTACT US
                </Typography>
                <hr  />
                </Grid>    
                <Grid item xs={12} sm={12} md={6}>
                    <Box boxShadow={3} p={3}  style={{ border:"2px groove #bfaa8d",backgroundColor:"white",marginTop:"10%",marginBottom:"10%",borderRadius:"10px"}}> 
                    <Grid
                    container
                    direction="row"
                        justify="space-between"
                        alignItems="center"
                    spacing={3}
                    >
                        <Grid item xs={12}>
                            <Typography variant="h4"   className="text-center" gutterBottom>
                                            Give your Details
                            </Typography>
                            <hr  />
                        </Grid> 
                        <Grid item xs={12}>
                        <TextField className={classes.root}  label="Name *"  variant="outlined" fullWidth
                    size="small"
                         
                        error={error===1 && true }
                        type={ 'text'  }
                        value={name}
                         
                         
                         
                        onChange={(e) => {
                                setName(e.target.value);

                        }}
                        
                    />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField className={classes.root}  label="Mobile *"  variant="outlined" fullWidth
                    size="small"
                         
                        error={error===2 && true }
                        type={ 'number'  }
                        value={mob}
                          
                         
                        onChange={(e) => {
                                setMob(e.target.value);

                        }}
                        
                    />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField className={classes.root}  label="Email"  variant="outlined" fullWidth
                    size="small"
                         
                         
                        type={ 'text'  }
                        value={email}
                         
                         
                       
                         
                        onChange={(e) => {
                                setEmail(e.target.value);

                        }}
                        
                    />
                        </Grid>
                         
                        <Grid item xs={12}>
                        <FormControl variant="outlined" className={classes.root}
                size="small"
                error={error===3&&true}
                fullWidth  >
                 <InputLabel >
                   Subject *
                 </InputLabel>
                 <Select
                  // native
                   value={sub}
                   onChange={(e) => {
                     setSub(e.target.value);
                      
                   }}
                   style={{color:theme.palette.primary.main}}
                    error={error === 3 ? true : false}
                   label="Subject"
                    
                 >
                     <MenuItem key="0"  value="0"  >Choose Enquiry for..</MenuItem>
                   <MenuItem key="Registration"  value="Registration"  >Registration</MenuItem>
                   <MenuItem key="Subscription"  value="Adding a Profile"  >Adding a Profile</MenuItem>
                   <MenuItem key="Real Estates"  value="My Profile Approval" >My Profile Approval</MenuItem>
                   <MenuItem key="Info in EngInfo"  value="Interacting with a Profile"  >Interacting with a Profile</MenuItem>
                    <MenuItem key="Others"  value="Others"  >Others</MenuItem>
                 </Select>
               </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                        <TextField className={classes.root}  label="Message *"  variant="outlined" fullWidth
                    size="small"
                         
                    error={error===4 && true }
                        type={ 'text'  }
                        value={msg}
                         
                         
                         multiline
                         rows={4}
                        onChange={(e) => {
                                setMsg(e.target.value);

                        }}
                        
                    />
                        </Grid>
                        <BackDropLoad wait={wait}/>
                        <Grid item xs={12} className="text-center">
                    <Button variant="contained" type="submit"  style={{width:"60%"}} color="primary" disabled={wait} className="linkstyle"  >
                    {wait?"Please Wait..":"Submit Details"}
                    </Button>
            </Grid>
                    </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                <Box boxShadow={3} p={3} m={4} style={{  marginTop:"10%",marginBottom:"10%"}}> 
                 <Typography variant="body1"  className="text-center" gutterBottom>
                                If you have any Queries please feel free to Reach us
                </Typography>
                <Typography variant="body1"  className="text-center" gutterBottom>
                               <b style={{color:theme.palette.secondary.main}}>Email-Id: </b> support@kapukalyanalu.com, kapukalyanalu@gmail.com.
                </Typography>  
                </Box>
                </Grid>
                
            </Grid>
            </form>
              </Box>}
    </Container>);
}