import {useState,useEffect,useContext} from 'react';
import {Grid,Container,Box,Button, Dialog,DialogActions,DialogContent, DialogContentText,DialogTitle,Typography,TextField,useTheme,makeStyles,ButtonBase} from '@material-ui/core';
import {UserContext,SnackbarContext} from './UserContext';
import {BackDropLoad} from './BackdropLoading';
import {useNavigate ,Link} from 'react-router-dom';

import { Helmet  } from 'react-helmet-async';
import LockIcon from '@material-ui/icons/Lock';
import PhoneIcon from '@material-ui/icons/Phone';

const headKey="asda()*^%~`)sdawqe23123>;p988u12hmmasd";
const axios = require('axios').default;


const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000000"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000000"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000000"
    },
    "& .MuiFormLabel-root":{
      color:"#000000"
    },
     
    "& .Mui-error":{
      color:"red  ",
     // borderColor:"red !important"
    },
    "& .MuiInputLabel-shrink":{
      
      transform: "translate(14px, -9px) scale(0.90)",
      transformOrigin: "top left",
      backgroundColor:"#ffffff",
      paddingLeft:"1%",
      paddingRight:"1%",
    },
    
     
  },
  nounderline: {
    "&:hover": { textDecoration:"none"},
  },
  cardshadow: {
    "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
     
  },

});

export const UserLogin = ()=>{
    let history = useNavigate ();
    const theme=useTheme();
    const classes = useStyles();


    const [title,setTitle] = useState(); 

    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);

    const [uname,setUname] = useState("");
    const [pass,setPass] = useState("");
    const [frnum,setFrNum] = useState("");
    const [otp,setOtp] = useState("");
    const [err,setErr] = useState(0);
    const [wait,setWait] = useState(false);

     
     
    const [newpass,setNewPass] = useState("");
    const [renewpass,setReNewPass] = useState("");


    const [open, setOpen] = useState(false);


    const [forgotMobileDailog,setForgotMobileDailog] = useState(false);
    const [valotpDailog,setValotpDailog] = useState(false);


    useEffect(()=>{
      window.scrollTo(0, 0);
      getMeta()
    },[])


    const getMeta=()=>{
      const formData= new FormData();
      formData.append('page','login');
      
       
      axios.post("/web/get_single_metadata",formData ).then(  (res) => {
             
              if(res.data.status ){
                setTitle(res.data.data.seo_title)
              }
      });     
    }


    const handleClose = () => {
        setOpen(false);
    };

    const login=(e)=>{
        setErr(0);
        e.preventDefault();
        if(uname===''){
            setErr(1);
            setSnack({
                message: 'Enter your Email or Mobile Number..',
                type: 'error',
                open: true,
                direction:"center"
            });
        }else if(pass===''){
            setErr(2);
            setSnack({
                message: 'Enter your Password..',
                type: 'error',
                open: true,
                direction:"center"
            });
        }else{
            setWait(true);
            const formData=new FormData();
            formData.append('uname',uname);
            formData.append('pass',pass);
            axios.post("/web/login",formData,{
                headers:{headKey:headKey}
              }).then((res)=>{
                 
                if(res.data.status){
                   
                    setSnack({
                        message: res.data.msg,
                        type: 'success',
                        open: true,
                        direction:"left"
                    });
                    setUser(res.data.data)
                    history('/myaccount/profile')
                  
            
                }else{
                  setWait(false)
                  setSnack({
                    message: res.data.msg,
                    type: 'error',
                    open: true,
                    direction:"center"
                });
                }
              });
        }
    }


    const generateotp=(e)=>{
      e.preventDefault();
      setErr(0);
      if(frnum.length!==10){
        setErr(3)
        setSnack({
          message: "Enter Valid 10-digit Mobile number..",
          type: 'error',
          open: true,
          direction:"center"
        })
      }else{
        setForgotMobileDailog(false);
        setWait(true);
        const formData=new FormData();
        formData.append('uname',frnum);
         
        axios.post("/web/forgotpassword",formData ).then((res) => {
             
              if(res.data.status ){
                   setWait(false);
                  setValotpDailog(true);
                  setSnack({
                    message: res.data.msg,
                    type: 'success',
                    open: true,
                    direction:"center"
                  });
              }else{
                setWait(false);
                setForgotMobileDailog(true);
                setSnack({
                  message: res.data.msg,
                  type: 'error',
                  open: true,
                  direction:"center"
                });
              }
              
            
         }); 
      }
    }


    const validateotp=(e)=>{
      e.preventDefault();
          setErr(0);
      if(otp===''){
        setErr(4)
        setSnack({
          message: "Enter Valid 10-digit Mobile number..",
          type: 'error',
          open: true,
          direction:"center"
        })
      }else if(newpass.length<5){
        setErr(5)
        setSnack({
          message: "Password should have atleast 5 characters..",
          type: 'error',
          open: true,
          direction:"center"
        })
      }else if(newpass!==renewpass){
        setErr(6)
        setSnack({
          message: "Password not Matching..",
          type: 'error',
          open: true,
          direction:"center"
        })
      }else{
        setValotpDailog(false)
         setWait(true);
        const formData=new FormData();
         
        formData.append('otp',otp)
        formData.append('pass',newpass)
         
        axios.post("/web/forgotpassword",formData, ).then((res) => {
             
              if(res.data.status ){
                  setWait(false);
                   
                  setValotpDailog(false);
                  setSnack({
                    message: res.data.msg,
                    type: 'success',
                    open: true,
                    direction:"center"
                  });
              }else{
                setWait(false);
                setValotpDailog(true);
                setSnack({
                  message: res.data.msg,
                  type: 'error',
                  open: true,
                  direction:"center"
                });
              }
              
            
         }); 
      }
    }

    return (<div>
        {title && <Helmet>
  <title>{title}</title>
     
 </Helmet>}
        <Container  maxWidth="sm"
             >
                <BackDropLoad wait={wait}/>
                <Dialog
        open={valotpDailog}
        onClose={()=>{
           
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
            style: {
              backgroundColor: "white",
              boxShadow: 'none',
            },
          }}
        //style={{backgroundColor:theme.palette.secondary.main}}
      >
           
          <DialogTitle id="alert-dialog-title"> <Typography variant="h5" className="text-center"  style={{color:theme.palette.primary.main   }}>
                                      Update Password           
                        </Typography>
                        <hr style={{color:theme.palette.primary.main}}/>
                        </DialogTitle>
          <DialogContent  style={{overflow:"hidden"}}>
          <form onSubmit={validateotp}>
           <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={3}
            >
                <Grid item xs={12}   >
                <TextField className={classes.root}  label="OTP (One Time Password) *"  variant="outlined" fullWidth
                    size="small"
                         
                        value={otp}
                         
                        error={err===4 && true }
                        InputProps={{
                            style: {
                                color:  "#000000"
                            },
                             
                        }}
                        //InputLabelProps={{  shrink: true,focused:true }} 
                        onChange={(e) => {
                                    setOtp(e.target.value);
                        }}
                        
                    />
                </Grid>
                <Grid item xs={12}>
             
             <TextField className={classes.root}  label="New Password *"  variant="outlined" fullWidth
                     size="small"
                          
                         error={err===5 && true }
                         type={ 'password'}
                         value={newpass}
                          
                         InputProps={{
                             style: {
                                 color:  "#000000"
                             },
                              
                         }}
                          
                         onChange={(e) => {
                                 setNewPass(e.target.value);
 
                         }}
                         
                     />
             </Grid>
             <Grid item xs={12}>
             
             <TextField className={classes.root}  label="Re-Enter New Password *"  variant="outlined" fullWidth
                     size="small"
                          
                         error={err===6 && true }
                         type={ 'password'}
                         value={renewpass}
                          
                         InputProps={{
                             style: {
                                 color:  "#000000"
                             },
                              
                         
                              
                         }}
                          
                         onChange={(e) => {
                                 setReNewPass(e.target.value);
 
                         }}
                         
                     />
             </Grid>  
                 
                
                <Grid item xs={12}  className="text-center" >
                <Button variant="contained" type="submit"  style={{width:"60%"}} fullWidth={false} color="primary"  className="linkstyle"  >UPDATE PASSWORD</Button>
                </Grid>
                <Grid item xs={12}   >
                 <br/>
                </Grid>
            </Grid>
            </form>
            </DialogContent>
             
      </Dialog>
                <Dialog
        open={forgotMobileDailog}
        onClose={()=>{
          setForgotMobileDailog(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
            style: {
              backgroundColor: "#ffffff",
              boxShadow: 'none',
            },
          }}
        //style={{backgroundColor:theme.palette.secondary.main}}
      >
           
          <DialogTitle id="alert-dialog-title"> <Typography variant="h5" className="text-center"  style={{color:theme.palette.primary.main   }}>
                                      Enter Registered Mobile no           
                        </Typography>
                        <hr style={{color:theme.palette.primary.main}}/>
                        </DialogTitle>
          <DialogContent  style={{overflow:"hidden"}}>
          <form onSubmit={generateotp}>
           <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={3}
            >
                <Grid item xs={12}   >
                <TextField className={classes.root}  label="Mobile no *"  variant="outlined" fullWidth
                    size="small"
                         
                        value={frnum}
                         
                        error={err===3 && true }
                        InputProps={{
                            style: {
                                color: "#000000"
                            },
                             
                        }}
                        //InputLabelProps={{  shrink: true,focused:true }} 
                        
                        onChange={(e) => {
                                 
                                    setFrNum(e.target.value);
                                
                                

                        }}
                        
                    />
                </Grid>
                 
                 
                 
                
                <Grid item xs={12}  className="text-center" >
                <Button variant="contained" type="submit"  style={{width:"60%"}} fullWidth={false} color="primary"  className="linkstyle"  >GENERATE OTP</Button>
                </Grid>
                <Grid item xs={12}   >
                 <br/>
                </Grid>
            </Grid>
            </form>
            </DialogContent>
             
      </Dialog>
                 
        <Box  boxShadow={3} p={3} m={4} style={{border:"2px solid #333333",borderRadius:"5px",backgroundColor:"white" }}>
        <form onSubmit={login}>
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={4}
            
        >
            <Grid item xs={12}>
                <Typography variant="h4" style={{color:theme.palette.secondary.main}} className="text-center" gutterBottom>
                                LOGIN  
                </Typography>
                <hr style={{color:theme.palette.secondary.main}}/>
            </Grid> 
            <Grid  item xs={12} > 
                <TextField id="outlined-basic" label="Mobile no / Email-ID *" variant="outlined" 
                className={classes.root}
                error={err===1 && true}
                value={uname}
                onChange={(e)=>{
                    setUname(e.target.value);
                }}
                size='small'
                  fullWidth  
                   
                  />
            </Grid>
            <Grid  item xs={12} > 
                <TextField id="outlined-basic" label="Password *" variant="outlined" className={classes.root}
                error={err===2 && true}
                type="password"
                value={pass}
                onChange={(e)=>{
                    setPass(e.target.value);
                }}
                size='small'
                 
                  fullWidth  />
            </Grid>
            <Grid item xs={12} className="text-right" >
              <Typography variant="body1" style={{float:"right"}}>
              <ButtonBase onClick={()=>{
                   setForgotMobileDailog(true);
                 }} style={{color:theme.palette.secondary.main,textDecoration:"underline"}}  >
                        Forgot Password?
                 </ButtonBase>
              </Typography>
                 
                </Grid>
            <Grid  item xs={12} className="text-center">
            <Button variant="contained" color="primary" style={{width:'60%'}}  className="linkstyle" type="submit" disabled={wait} >
               {wait?"Please wait..":"LOGIN"} 
            </Button>
            </Grid>
            <Grid item xs={12} className="text-center" >
            <hr style={{margin:"0px"}} />
            </Grid>
            <Grid item xs={12} className="text-center" >
              <Typography variant="body1"  >
              <ButtonBase   component={Link} to={"/signup"} style={{color:theme.palette.secondary.main,textDecoration:"underline"}}  >
              Not a Member yet?&nbsp;<b>Register Now</b>
                 </ButtonBase>
              </Typography>
                 
                </Grid>
        </Grid>
        </form>
        </Box>
        </Container>
    </div>);
}