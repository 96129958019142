import {useEffect,useState} from "react";
 
 import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container ,useTheme,Box,Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet  } from 'react-helmet-async';
import {Loading } from "./loading";
import {withStyles} from '@material-ui/core/styles';
const axios = require('axios').default;
const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFBF00"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFBF00"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFBF00"
    },
    "& .MuiFormLabel-root":{
      color:"#FFBF00"
    },
     
    "& .MuiInputLabel-shrink":{
      
      transform: "translate(14px, -9px) scale(0.90)",
      transformOrigin: "top left",
      backgroundColor:"black",
      paddingLeft:"1%",
      paddingRight:"1%",
    } 
     
  },
  cardshadow: {
    "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
     
  },
});

const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";

export const Terms=()=>{
  const classes = useStyles();
  const theme = useTheme();
  const [metadata,setMetadata] = useState();
  const [title,setTitle] = useState();
  const [loading,setLoading] = useState(true);
  useEffect(()=>{
    window.scrollTo(0, 0);
    getMeta()  
  },[])


  const getMeta=()=>{
    const formData= new FormData();
    formData.append('page','terms');
    
     
    axios.post("/web/get_single_metadata",formData ).then(  (res) => {
           
            if(res.data.status ){
              setTitle(res.data.data.seo_title)
              setLoading(false)
            }
          });     
  }

return  (  <div> {title &&<Helmet>
  <title>{title}</title>
    
</Helmet>} <Container maxWidth="md">
{loading?<Loading/>:<Box boxShadow={3} p={3}  className={classes.cardshadow}  style={{ border:"2px groove #bfaa8d",backgroundColor:"white",marginTop:"5%",marginBottom:"5%",borderRadius:"10px",backgroundImage:"url('/static/backgrounds/profilebg.jpg')" }}> 
<Typography variant="h4" className="text-center"   >
                                     Our Terms & Conditions          
                        </Typography>
                        <hr  />
                        <br/>
                        <Grid
                          container
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          spacing={3}
                        >
                          
                           <Grid item xs={12}>
                           <Typography variant="h5">
                                <u>Account:</u>
                           </Typography>
                             <Typography variant="body1"   style={{textAlign:"justify"}}  >
                             &nbsp;&nbsp;&nbsp;To use this website, you must be at least 18 years of age and by using this website and agreeing to the terms and conditions, you are verifying the information you’ve provided to us. If you create an account on the website, you are solely responsible for all the activities that occur and providing false information of any kind will result in the permanent termination of your account. We may suspend or delete your account if we determine any violation of the terms and conditions.
                               </Typography>
                          </Grid>
                          <Grid item xs={12}>
                           <Typography variant="h5">
                                <u>Eligibility:</u>
                           </Typography>
                             <Typography variant="body1"   style={{textAlign:"justify"}}  >
                               &nbsp;&nbsp;&nbsp;The Added Bride or Groom's Profile must be at least 21 years of age or over.
                                </Typography>
                          </Grid>
                          <Grid item xs={12}>
                           <Typography variant="h5">
                                <u>Added/Edited Profile Approval and Rejection:</u>
                           </Typography>
                             <Typography variant="body1"   style={{textAlign:"justify"}}  >
                             &nbsp;&nbsp;&nbsp;Every Added or Edited profile will go through the verification process by our backend team. Once it gets approved it will be visible for all users.  
                             <br/>
                             &nbsp;&nbsp;&nbsp;The user need to upload a scanned copy of supporting document like aadhar or pan card or driver's license etc to valid the Profile.
                             </Typography>
                           </Grid>  
                          <Grid item xs={12}>
                           <Typography variant="h5">
                                <u>Termination:</u>
                           </Typography>
                             <Typography variant="body1"   style={{textAlign:"justify"}}  >
                             &nbsp;&nbsp;&nbsp;The following will lead to membership termination:
                             <ul>
                               <li>Using the website/services for illicit sexual relations or extramarital affairs.</li>
                               <li>Uploading false, inaccurate, or misleading information regarding him/her.</li>
                               
                             </ul>
                             </Typography>
                          </Grid>
                          <Grid item xs={12}>
                           <Typography variant="h5">
                                <u>Prohibited uses:</u>
                           </Typography>
                             <Typography variant="body1"   style={{textAlign:"justify"}}  >
                             &nbsp;&nbsp;&nbsp;In addition to other terms in the agreement, you are prohibited from using the Website or its content:
                             <ul>
                               <li>for any illegal purposes or acts.</li>
                               <li>to violate any kind of rules or laws.</li>
                               <li>to defame, slander or discriminate on any basis.</li>
                               <li>to harm or interfere with the functioning of the website through any manner.</li>
                               <li>Having said that, we reserve the right to terminate your use of our website or its services for violating any of the prohibited uses.</li>
                             </ul>
                             </Typography>
                          </Grid>
                          <Grid item xs={12}>
                           <Typography variant="h5">
                                <u>Related changes and amendments:</u>
                           </Typography>
                             <Typography variant="body1"   style={{textAlign:"justify"}}  >
                             &nbsp;&nbsp;&nbsp;We reserve the right to modify the terms and conditions, policies related to the website or the services provided at any time for any reason whatsoever at our discretion. It should be noted that continued usage of the website after any such changes will mean that you have given your consent to the said changes. We will not be liable to you or any third party for modifications involving price changes, suspension or discontinuation of the site.
                             </Typography>
                           </Grid>    
                           
                           <Grid item xs={12}>
                           <Typography variant="h5">
                                <u>Acceptance:</u>
                           </Typography>
                             <Typography variant="body1"   style={{textAlign:"justify"}}  >
                             &nbsp;&nbsp;&nbsp;We request you to read the following terms and conditions carefully before using our services. By registering to avail the services provided by us or indicating your acceptance through other means, you are agreeing to the said terms. If you do not wish to accept the terms of use, do not continue to use the website to avail our services.
<p style={{margin:"0px",textAlign:"center"}}><b>OR</b></p>
By using the site or the services it provides, you agree to be abide by the terms and conditions put forth. If you do not agree or accept the terms of this agreement, you are advised to not use or access the website and its services.
                             </Typography>
                           </Grid>    
                          <Grid item xs={12}>
                          <Typography variant="h5" className="text-center"   style={{color:theme.palette.primary.main}}>
                              ****
                            </Typography>
                          </Grid>

                        </Grid>
  </Box>}
  </Container>
      
     
</div> );
}

 

 

 

 