import {useState,useEffect,useContext} from 'react';
import {Grid,Container,Box,Button, makeStyles, Typography,Dialog,DialogActions,DialogContent,Card,CardMedia,Select,MenuItem,Avatar,FormLabel, DialogContentText,DialogTitle,Snackbar,TextField,useTheme} from '@material-ui/core';
import {UserContext,SnackbarContext} from './UserContext';
import {BackDropLoad} from './BackdropLoading';
import {Loading } from "./loading";
import {useNavigate,useParams,Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from "react-responsive-carousel";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import EditIcon from '@material-ui/icons/Edit';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {Helmet} from 'react-helmet-async';


import Compressor from 'compressorjs';
 
var momentt = require("moment-timezone");
const headKey="asda()*^%~`)sdawqe23123>;p988u12hmmasd";
const axios = require('axios').default;


const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "& .MuiFormLabel-root":{
        color:"primary"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#ffffff",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });

export const ViewProfile = ()=>{
    let {id} = useParams();
    let history = useNavigate ();
    const theme = useTheme();
      const classes = useStyles();
    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);

    const [isloading,setIsloading] = useState(true);

    const [activeStep, setActiveStep] =  useState(0);




    //lists
    const [status,setStatus] = useState();
     


    const [data,setData] = useState();
    
    const [dob,setDob] = useState("");
     

     
    //profilePic and Photos 35
    const [dpic,setDpic] = useState();
    const [olddpic,setOldDpic] = useState();

    const [photos,setPhotos] = useState([]);
    const [oldphotos,setOldPhotos] = useState([]);

    const [err,setErr] = useState(0);
    const [wait,setWait] = useState(false);

    const [open,setOpen] = useState(false);

    useEffect(()=>{
      window.scrollTo(0, 0);
        getdata();
    },[])


    function toFeet(n) {
      var realFeet = ((n*0.393700) / 12);
      var feet = Math.floor(realFeet);
      var inches = Math.round((realFeet - feet) * 12);
      return feet + " feet " + inches + ' inches';
    }


     
    

    const getdata=()=>{
        const formData=new FormData();
        formData.append('id',id);
        axios.post("/web/update_profile",formData ).then((res)=>{
                 
            if(res.data.status===1){
                  
                 setData(res.data.data)
                var dob=String(momentt(res.data.data.dob).format('DD-MM-YYYY'));
                setDob(dob);
                  

                  

                setStatus(res.data.data.status.toString()); 

                setOldDpic(res.data.data.dpic)
                var arr=["/send_file/profilepics/"+res.data.data.dpic].concat(res.data.data.photos.split(","))
                for(var i=0;i<arr.length;i++){
                    if(i!=0){
                      arr[i]="/send_file/photos/"+arr[i];
                    }
                }
                setOldPhotos(arr)


                 
                setIsloading(false)
            }else if(res.data.status===3){
              setSnack({
                message: res.data.msg,
                type: 'warning',
                open: true,
                direction:"left"
            });
              setUser(null)
              history("/")
            }
        });
    } 

 
  
 

   
    function tConvert (time) {
        // Check correct time format and split into components
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      
        if (time.length > 1) { // If time format correct
          time = time.slice (1);  // Remove full string match value
          time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
          time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join (''); // return adjusted time or original string
      }

     
    

    return (<div>
         {data && <Helmet>
  <title>{data.name} {data.surname}'s Profile | Kapu Kalyanalu</title>
     
 </Helmet>}
        <Container  
             >
                <BackDropLoad wait={wait}/>
                 
       {isloading?<Loading/>:( <Box  boxShadow={3} p={3}   style={{border:"2px solid #bfaa8d",borderRadius:"5px",marginBottom:"10%",backgroundImage:"url('/static/backgrounds/profilebg.jpg')" }}>
        
                <h3 className='text-center'> Profile Details </h3>
             <hr/>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={4}
                        >

                            <Grid item xs={12} sm={4} className="text-center">
                                  <Button variant="contained" component={Link} className="linkstyle"  color="primary" to={"/myaccount/profiles" } >
                                 <ArrowBackIosIcon/>  Go to Profiles</Button>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                            {status==='1' && <Typography variant="body1" gutterBottom><b>Profile Status:</b> <span style={{color:"green"  }}> Active </span> </Typography>}
                            {status==='2' && <Typography variant="body1" gutterBottom><b>Profile Status:</b> <span style={{color:"red"  }}> Rejected </span> </Typography>}
                            {status==='0' && <Typography variant="body1" gutterBottom><b>Profile Status:</b> <span style={{color:"black"  }}> Waiting for Approval </span> </Typography>}
                            </Grid>
                             
                            <Grid item xs={12} sm={4} className="text-center">
                            <Button variant="contained" component={Link} className="linkstyle"  color="primary" to={"/myaccount/edit_profile/"+id} >
                                 <EditIcon/>&nbsp;EDIT PROFILE</Button>
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} >
                            {oldphotos && (<Carousel
                                      animation="slide"
                                      timeout={1200}
                                      autoPlay={false}
                                      showIndicators={false}
                                      showArrows={true}
                                      centerMode={true}
                                      centerSlidePercentage={100}
                                      infiniteLoop
                                      
                                      stopOnHover
                                      swipeable
                                      showThumbs={true}
                                      dynamicHeight={true}
                                      transitionTime={1200}
                                      statusFormatter={(current, total) => {
                                      // setActive(current - 1);
                                        return ``;
                                      }}
                                    >
                                      {oldphotos.map((photo, i) => (
                                        <div  
                                        className="MuiPaper-elevation1"
                                        style={{
                                          //margin:"10px",
                                          //border:"2px groove black",
                                         padding:"5px",
                                      
                                          borderRadius:"5px",
                                        // maxHeight:"300px"
                                        //filter: "blur(1px)",
                                        display: "flex",
                                        //width: "300px", 
                                        alignItems: "center",
                                         
                                        flexWrap:"wrap"
                                        }}
                                        >
                                        
                                              <img src={photo} style={{borderRadius:"5px",maxHeight:"100%"   }}   alt={photo} />
                                    </div>
                                      ))}
                                    </Carousel>)}
                            </Grid>
                            <Grid item xs={12} sm={6} md={7}>
                                <br/>
                                <table className="table table-striped">
                                {(data.reject_reason!=null && data.reject_reason!='' && status==='2' )&& <tr>
                                    <td colSpan="2">
                                      
                                      <ol>
                                      <h5>Please Resolve the Below Issues and Resubmit again.</h5>
                                    {data.reject_reason.split(",").map((reason) => (
                                      <li style={{color:"red"}}>{reason}</li>
                                    ))}
                                    </ol>
                                    </td> 
                                    </tr>}
                                  <tr>
                                    <td colSpan="2">
                                        <Typography variant="body1" gutterBottom>Profile Verification File: <a href={"/send_file/doc/"+data.aadhar_link} target="_blank">view file</a> </Typography>
                                    </td>
                                  </tr>
                                  
                                  <tr>
                                    <td><Typography variant="body1" gutterBottom><b>Full Name:</b> {data.name} {data.surname}</Typography></td>
                                    <td><Typography variant="body1" gutterBottom><b>Gender:</b> {data.gender}</Typography></td>
                                  </tr>
                                  <tr>
                                    <td><Typography variant="body1" gutterBottom><b>Date of Birth:</b> {dob}</Typography></td>
                                    <td><Typography variant="body1" gutterBottom> <b>Age:</b> {data.realage}</Typography></td>
                                  </tr>
                                  <tr>
                                    <td> <Typography variant="body1" gutterBottom><b>Caste:</b> {data.caste}  </Typography></td>
                                    <td><Typography variant="body1" gutterBottom> <b>Marital Status:</b> {data.maritalstatus}</Typography></td>
                                  </tr>
                                  <tr>
                                    <td><Typography variant="body1" gutterBottom><b>Mobile Number:</b> {data.mob}</Typography></td>
                                    <td><Typography variant="body1" gutterBottom> <b>Email-ID:</b> {data.email}</Typography></td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2"><Typography variant="body1" gutterBottom><b>Current Country:</b> {data.country}</Typography></td>
                                  </tr>
                                  <tr>
                                    <td><Typography variant="body1" gutterBottom><b>Star Sign:</b> {data.star}</Typography></td>
                                    <td><Typography variant="body1" gutterBottom><b>Gothram:</b> {data.gothram}</Typography></td>
                                  </tr>
                                  <tr>
                                    <td><Typography variant="body1" gutterBottom> <b>Time of Birth:</b> {tConvert(data.birthtime)   }</Typography></td>
                                    <td><Typography variant="body1" gutterBottom> <b>Birth Place:</b> {data.birthplace}</Typography></td>
                                  </tr>
                                  <tr>
                                    <td><Typography variant="body1" gutterBottom> <b>Height in Feet & Inches:</b> {toFeet(data.height)}</Typography></td>
                                    <td><Typography variant="body1" gutterBottom> <b>Complexion:</b> {data.complex}</Typography></td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2"><Typography variant="body1" gutterBottom><b>About:</b> {data.about}</Typography></td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2"><Typography variant="body1" gutterBottom><b>Hobbies:</b> {data.hobbies}</Typography></td>
                                  </tr>
                                </table>
                                 
                            </Grid>
                            
                            <Grid item xs={12} sm={6}  >
                            <hr/>
                            <table className="table table-striped">
                              <tr>
                                  <td className="text-center">
                                        <Typography variant="body1" gutterBottom><b><u>Family Details</u></b></Typography>
                                  </td>
                              </tr>
                              <tr>
                                  <td >
                                  <Typography variant="body1" gutterBottom><b>Father Name:</b> {data.fathername}  </Typography>
                                  </td>
                              </tr>
                              <tr>
                                  <td >
                                  <Typography variant="body1" gutterBottom><b>Father's Designation:</b> {data.fatherDesignation}  </Typography>
                                  </td>
                              </tr>
                              <tr>
                                  <td >
                                  <Typography variant="body1" gutterBottom><b>Mother Name:</b> {data.mothername}  </Typography>
                                  </td>
                              </tr>
                              {(data.motherDesignation!==null && data.motherDesignation!=='')&&<tr>
                                  <td >
                                  <Typography variant="body1" gutterBottom><b>Mother's Designation:</b> {data.motherDesignation}</Typography>
                                  </td>
                              </tr>}
                              {(data.familyDetails!==null && data.familyDetails!=='')&&<tr>
                                  <td >
                                  <Typography variant="body1" gutterBottom><b>Other Family Details:</b> {data.familyDetails}</Typography>
                                  </td>
                              </tr>}
                            </table>
                          
                               
                              
                            </Grid>
                            <Grid item xs={12} sm={6}  >
                            <hr/>
                            <table className="table table-striped">
                              <tr>
                                  <td className="text-center">
                                        <Typography variant="body1" gutterBottom><b><u>Work Details</u></b></Typography>
                                  </td>
                              </tr>
                              <tr>
                                  <td >
                                  <Typography variant="body1" gutterBottom><b>Profession Category:</b> {data.profCat}</Typography>
                                  </td>
                              </tr>
                              <tr>
                                  <td >
                                  <Typography variant="body1" gutterBottom><b>Current Designation:</b> {data.designation}</Typography>
                                  </td>
                              </tr>
                              <tr>
                                  <td >
                                  <Typography variant="body1" gutterBottom><b>Current Organisation:</b> {data.currentOrgName}</Typography>
                                  </td>
                              </tr>
                              <tr>
                                  <td >
                                  <Typography variant="body1" gutterBottom><b>Salary: </b> {data.currentSal} (in Lakhs per Year / Annum)</Typography>
                                  </td>
                              </tr>
                              {(data.workDetails!==null && data.workDetails!=='')&&<tr>
                                  <td >
                                  <Typography variant="body1" gutterBottom><b>Other Work Details:</b> {data.workDetails}</Typography>
                                  </td>
                              </tr>}
                            </table>  
                
                            </Grid>
                            <Grid item xs={12} sm={6}   >
                            <hr/>
                            <table className="table table-striped">
                              <tr>
                                  <td className="text-center">
                                        <Typography variant="body1" gutterBottom><b><u>Education Details</u></b></Typography>
                                  </td>
                              </tr>
                              <tr>
                                  <td >
                                  <Typography variant="body1" gutterBottom><b>Magor Degree:</b> {data.majorDegree} </Typography>
                                  </td>
                              </tr>
                              <tr>
                                  <td >
                                  <Typography variant="body1" gutterBottom><b>College/University:</b> {data.college} </Typography>
                                  </td>
                              </tr>
                              <tr>
                                  <td >
                                  <Typography variant="body1" gutterBottom><b>Year of Passing:</b> {data.yearOfPassing} </Typography>
                                  </td>
                              </tr>
                              {(data.eduDetails!==null && data.eduDetails!=='')&&<tr>
                                  <td >
                                  <Typography variant="body1" gutterBottom><b>Other Education Details:</b> {data.eduDetails}</Typography>
                                  </td>
                              </tr>}
                            </table>  
                              
                            </Grid>

                </Grid>            
            
             
    
        </Box>)}
        </Container>
         
    </div>);
}