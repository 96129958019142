import logo from './logo.svg';
import './App.css';
import {useState,useEffect,useMemo} from 'react';
import {BrowserRouter as Router,Routes,
  Route,Navigate ,
  Link} from 'react-router-dom';

 
 
import {Loading } from "./components/loading";  
 
import {Footer } from "./components/footer"; 
import {Home } from "./components/home";
import {SearchResults } from "./components/searchresults";
import {SingleProfile } from "./components/singleprofile";
import {UserLogin } from "./components/login";
import {About } from "./components/about";
import {Terms } from "./components/terms";
import {Privacy } from "./components/privacy";
import {Signup } from "./components/signup";
import {MyAccount } from "./components/myaccount";
import {WebNavbar } from "./components/WebNav"; 
import {ContactNow} from './components/contact';
import {UserContext,SnackbarContext} from './components/UserContext';
 
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import Alert from "@material-ui/lab/Alert";
import { createTheme, ThemeProvider, useTheme } from '@material-ui/core/styles';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap/dist/js/bootstrap.min.js';
const headKey="asda()*^%~`)sdawqe23123>;p988u12hmmasd";
const axios = require('axios').default;



const themes = createTheme({
  palette: {
    primary: {
      light: "#bfaa8d",
      main: "#bfaa8d",//"#d4af37" Gold,//"#e98074" red,//"#e85a4f",
      dark: "#bfaa8d",
      contrastText: "#000000",
    },

    secondary: {
      light: "#666666",
      main: "#2e2b2b",
      dark: "#666666",
      contrastText: "#ffffff",
    },
     third:{
      light: "#c5c5c5",
      main: "#c5c5c5",
      dark: "#c5c5c5",
      contrastText: "#ffffff",
      
     },
    white: {
      light: "white",
      main: "white",
      dark: "white",
      contrastText: "white",
    },
  },
});



function App() {

  const th=useTheme();
  const [isloading,setIsloading] = useState(true);
  const [user,setUser] = useState(null);

   

  const [snack, setSnack] = useState({
    message: '',
    type: 'success',
    open: false,
    direction:"center"
  });

  const value = useMemo(()=>({user,setUser}),[user,setUser]);

   

  const snackValue = useMemo(()=>({snack, setSnack}),[snack, setSnack]);

   

  useEffect(()=>{
    checksession();
  },[]);

  const checksession=()=>{
    axios.post("/web/getsession",{
      headers:{headKey:headKey}
    }).then((res)=>{
       
      if(res.data.status){
        setUser(res.data.data);
        setIsloading(false);
      }else{
        setUser(null);
        setIsloading(false);
      }
    });
  }

  return (
    <ThemeProvider theme={themes}>
      <HelmetProvider>
    <div className="App" 
    //style={{backgroundColor:"#eae7dc"}}
    //style={{backgroundColor:th.palette.secondary.primary.main}}
    >
      
        {isloading?(<Loading/>):(<div className="page-container" >
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: snack.direction,
          }}
          autoHideDuration={3000}
          open={snack.open}
          onClose={()=>{
            setSnack(prevdata => {
              return { 
                ...prevdata, 
                open:false
              }
            })
          }}
          TransitionComponent={Slide}
        >
          <Alert variant="filled" onClose={()=>{
              setSnack(prevdata => {
                return { 
                  ...prevdata, 
                  open:false
                }
              })
          }} severity={snack.type}>
            {snack.message}
          </Alert>
        </Snackbar>
         
        <UserContext.Provider value={value}>
        <SnackbarContext.Provider value={{ snack, setSnack }}>
        <Router>
            <WebNavbar />
            <div class="content-wrap">
            
            <Routes>
                <Route path="/"  element={ <Home />} />
                <Route path="/contact-us"  element={ <ContactNow />} />
                <Route path="/about-us"  element={ <About />} />
                <Route path="/terms"  element={ <Terms />} />
                <Route path="/privacy"  element={ <Privacy />} />
                <Route path="/login"  element={user ? (<Navigate  to="/myaccount/profile" />) :(<UserLogin />)} />
                <Route path="/signup"  element={user ? (<Navigate  to="/myaccount/profile" />) :(<Signup />)} />
                <Route path="/profile/:id"  element={user ? (<SingleProfile />) :(<Navigate  to="/login" />)} />
                <Route path="/searchresults/:pf/:ms/:ca/:co/:pc/:age/:sb"  element={user?(<SearchResults />):(<Navigate  to="/login" />)
                  } />
                <Route path="/myaccount/*"  element={user ? (<MyAccount />):(<Navigate  to="/login" />)  } />
                <Route path="*"  element={ <Navigate  to="/" />} />
            </Routes>
            </div>
            <br/><br/>
            <Footer/>
        </Router>  
        </SnackbarContext.Provider>  
        </UserContext.Provider>
         
        </div>
       )}
        
    </div>
    </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
