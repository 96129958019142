
import {useState,useEffect,useContext} from 'react';
import {UserContext,SnackbarContext} from './UserContext';
import {Grid, Hidden,useTheme,Card,CardContent,ButtonBase,Button,makeStyles,Container,Box} from '@material-ui/core';
import {BackDropLoad} from './BackdropLoading';
import {Loading } from "./loading";
import {EditUser } from "./edituser";
import {ViewProfile } from "./viewprofile";
import {EditProfile } from "./editprofile";
import {AddProfile } from "./addprofile";
import {MyReferredProfiles } from "./myreferredprofiles";
import {MyRequestedProfiles } from "./myrequestedprofiles";
import {UpdatePassword } from "./updatepassword";
import {UserProfiles } from "./userprofiles";
import {MyProfile } from "./myprofile";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockIcon from '@material-ui/icons/Lock';
import PeopleIcon from '@material-ui/icons/People';
import HistoryIcon from '@material-ui/icons/History';
 
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
 
import EditIcon from '@material-ui/icons/Edit';

import { Routes,Navigate,useNavigate,Link,
    Route } from 'react-router-dom';


const axios = require('axios').default;

export const MyAccount=()=>{


    const [wait,setWait] = useState(false);
    const history=useNavigate();
    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    const logout=()=>{
        setWait(true)
        axios.post( "/web/logout" ).then((res) => {
            if(res.data.status){
              setUser(null);
              setWait(false);
             history('/');
             setSnack({
                message: res.data.msg,
                type: 'success',
                open: true,
                direction:"left"
              });
            } 
         }); 
    }


    return (<div>
        <Container  maxWidth="xl">
        <BackDropLoad wait={wait} />
        <br/>
         
         <br/><br/>
         <Grid
               container
               direction="row"
               justify="space-between"
               alignItems="flex-start"
               spacing={2}
               >
                    
                   <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                   <Box  boxShadow={3} p={3}   style={{border:"2px solid #bfaa8d",borderRadius:"5px" ,backgroundColor:"white",marginBottom:"10%",backgroundImage:"url('/static/backgrounds/profilebg.jpg')"}}>
         
                   <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="stretch"
                        spacing={3}
                    >
                  
                        {user&&(<Grid item   xs={12}>
         <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/myaccount/profile"} className="linkstyle"  ><AccountCircleIcon/>&nbsp;&nbsp;My Account</Button>
        </Grid>)}
                        {user&&(<Grid item   xs={12}>
                            <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/myaccount/profiles"} className="linkstyle"  ><PeopleIcon/>&nbsp;&nbsp;My Profiles</Button>
                            </Grid>)}
                            {user&&(<Grid item   xs={12}>
                            <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/myaccount/requests_history"} className="linkstyle"  ><SupervisorAccountIcon/>&nbsp;&nbsp;Requested Profiles</Button>
                            </Grid>)}
                            {user&&(<Grid item   xs={12}>
                            <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/myaccount/suggested_profiles"} className="linkstyle"  ><RecordVoiceOverIcon/>&nbsp;&nbsp;Suggested Profiles</Button>
                            </Grid>)}
                        {user&&(<Grid item   xs={12}>
                            <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/myaccount/updatedetails"} className="linkstyle"  ><EditIcon/>&nbsp;&nbsp;Update Details</Button>
                            </Grid>)}
                            {user&&(<Grid item   xs={12}>
                            <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/myaccount/updatepassword"} className="linkstyle"  ><LockIcon/>&nbsp;&nbsp;Update Password</Button>
                            </Grid>)}
                        {user&&(<Grid item   xs={12} >
         <Button variant="contained" className="text-center"  fullWidth={true} color="primary"  className="linkstyle"   onClick={logout} ><ExitToAppIcon/>&nbsp;&nbsp;LOGOUT</Button>
        </Grid>)}
                        </Grid>
                        </Box>
                   </Grid>
                 

                   <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                   <Routes>
                        <Route path="profile" element={user?  (<MyProfile /> ):(<Navigate  to="/login" />)} />
                        <Route path="profiles" element={user?  (<UserProfiles /> ):(<Navigate  to="/login" />)} />
                        <Route path="view_profile/:id" element={user?  (<ViewProfile /> ):(<Navigate  to="/login" />)} />
                        <Route path="edit_profile/:id" element={user?  (<EditProfile /> ):(<Navigate  to="/login" />)} />
                        <Route path="updatedetails" element={user?  (<EditUser /> ):(<Navigate  to="/login" />)} />
                        <Route path="add_profile" element={user?  (<AddProfile /> ):(<Navigate  to="/login" />)} />
                        <Route path="updatepassword" element={user?  (<UpdatePassword /> ):(<Navigate  to="/login" />)} />
                        <Route path="suggested_profiles" element={user?  (<MyReferredProfiles /> ):(<Navigate  to="/login" />)} />
                        <Route path="requests_history" element={user?  (<MyRequestedProfiles /> ):(<Navigate  to="/login" />)} />
                   </Routes>
                   </Grid>
         </Grid>           

        </Container>
    </div>)
}