import {useEffect,useState} from "react";
 
 import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container ,useTheme,Box,Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet  } from 'react-helmet-async';
import {Loading } from "./loading";
import {withStyles} from '@material-ui/core/styles';
const axios = require('axios').default;
const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFBF00"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFBF00"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFBF00"
    },
    "& .MuiFormLabel-root":{
      color:"#FFBF00"
    },
     
    "& .MuiInputLabel-shrink":{
      
      transform: "translate(14px, -9px) scale(0.90)",
      transformOrigin: "top left",
      backgroundColor:"black",
      paddingLeft:"1%",
      paddingRight:"1%",
    } 
     
  },
  cardshadow: {
    "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
     
  },
});

const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";

export const About=()=>{
  const classes = useStyles();
  const theme = useTheme();
  const [title,setTitle] = useState();

  const [loading,setLoading] = useState(true);

  useEffect(()=>{
    window.scrollTo(0, 0);
     getMeta();
  },[])


  const getMeta=()=>{
    const formData= new FormData();
    formData.append('page','about');
    
     
    axios.post("/web/get_single_metadata",formData ).then(  (res) => {
           
            if(res.data.status ){
              setTitle(res.data.data.seo_title)
              setLoading(false)
            }
          });     
  }

return  (  <div>

 {title && <Helmet>
  <title>{title}</title>
     
 </Helmet>} <Container maxWidth="md">
{loading?<Loading/>:<Box boxShadow={3} p={3} className={classes.cardshadow}    style={{ border:"2px groove #bfaa8d",backgroundColor:"white",marginTop:"5%",marginBottom:"5%",borderRadius:"10px",backgroundImage:"url('/static/backgrounds/profilebg.jpg')" }}> 
<Typography variant="h4" className="text-center"   >
                                     About us        
                        </Typography>
                        <hr  />
                        <br/>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-evenly"
                          alignItems="center"
                          spacing={3}
                        >

                          <Grid item xs={12} sm={4} md={4} className="text-center">
                          <Typography variant="h5"   gutterBottom   >
                             <img src="/static/kkcircle.png" className="img img-fluid" style={{height:"200px",border:"2px solid black",borderRadius:"100px"}}/>
                           </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8} md={8} className="text-center">
                            <Typography variant="body1"  style={{textAlign:"justify",marginBottom:"10px"}}  >
                             &nbsp;&nbsp;&nbsp;&nbsp;<b>Kapu Kalyanalu</b> is a matrimonial service offering various opportunities to people of the <b>Kapu Community</b> to meet their life partners. It has started originally as <b>"Kapu Vadhu Varula
Parichayalu"</b> by <b>Mr.Thota Naga Babu</b> from <b>Seesali Village near Bhimavaram, West Godavari District, Andhra Pradesh</b> to help everyone finds their better half.<br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;He has already selflessly served many people and started serving right
from the age of 20 in 1993 when an RMP Doctor of his village died in a
road accident leaving behind the dependents of about 10 family
members. The incident disturbed the <b>young Thota Naga Babu</b> so much
and to give a helping hand to the bereaved family he has mobilized and
raised funds of Rs.1.70 Lakhs from his own pocket, friends, and relatives.
Since then, people started turning to him and he used to rise for the cause
of every such occasion.
                               </Typography>
                              
                            </Grid>
                            <Grid item xs={12}>
                            <Typography variant="body1"  style={{textAlign:"justify",margin:"10px"}}  >
                              &nbsp;&nbsp;&nbsp;&nbsp;Regarding this matrimonial service, he started this first in 2018
connecting the people over inquiries on the phone, and on increase in
crowd, he started monitoring and connecting the People on Various
Social Media pages and groups. To date, he has been maintaining about 28
WhatsApp groups which are increasing day by day. So far 1500 eligible people
found their life partners.
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <hr/>
                            </Grid>
                            <Grid item xs={12} sm={8} md={8} className="text-center">
                            <Typography variant="body1"  style={{textAlign:"justify",margin:"10px"}}  >
                            &nbsp;&nbsp;&nbsp;&nbsp;Since he had to spend days on end on these profiles posting, we at <b>Arawinz
Soft Solutions</b> in the field of IT for 4 years pitched in to help him. <b>The CEO of
the Company, Mr Chethan Pasupuleti</b> have identified the thrust of <b>Mr.
Thota Naga Babu</b> towards the community service and decided to
extend our support by developing an Application with the Application
Development team. We wanted to help him in expanding his vision and
streamlining the process and providing the best user experience.<br/><br/>Every user need to follow three simple steps:<br/>
                            <ol>
                              <li>
                              Register with <b>KapuKalyanalu</b>.
                              </li>
                              <li>
                              Add your <b>Bride/Groom's</b> Profile.
                              </li>
                              <li>
                              <b>Search</b> and <b>Interact</b> with the profile that suits your Requirement.
                              </li>
                            </ol>
                            
                             </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} className="text-center">
                          <Typography variant="h5"   gutterBottom   >
                            <a href="https://www.arawinz.com" target="_blank">
                             <img src="/static/arawinz_logo.png" className="img img-fluid" style={{border:"2px solid black",borderRadius:"5px"}} />
                             </a>
                           </Typography>
                           </Grid>
                           <Grid item xs={12}  className="text-center">
                           <Typography variant="body1"  style={{textAlign:"justify",margin:"10px"}}  >
                           &nbsp;&nbsp;&nbsp;&nbsp;Every <b>Added</b> or <b>Edited</b> profile will go through the <b>verification process</b> by our backend team. Once it gets approved it will be <b>visible</b> for all users.<br/><br/>
                           &nbsp;&nbsp;&nbsp;&nbsp;We want to reach as many people as possible in finding their life partners through our portal. Looking forward to serving you more and better. We <b>Thank you</b> from the bottom of our hearts for using our service.
</Typography>
</Grid>
                          <Grid item xs={12}>
                          <Typography variant="h5" className="text-center"   style={{color:theme.palette.primary.main}}>
                              ****
                            </Typography>
                          </Grid>

                        </Grid>
  </Box>}
  </Container>
      
     
</div> );
}

 

 

 

 