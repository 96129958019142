
 
import {useState,useEffect,useContext,useLayoutEffect} from 'react';
import {Grid,Container,Collapse, Box,Button, makeStyles, RadioGroup,Radio,FormControlLabel, FormControl,InputLabel,Card,CardMedia,Select,MenuItem,ButtonBase,FormLabel,DialogContent, Dialog,DialogTitle,Snackbar,TextField,useTheme, CardContent, Typography} from '@material-ui/core';
import {UserContext,SnackbarContext} from './UserContext';
import {BackDropLoad} from './BackdropLoading';
import {Loading } from "./loading";
import {  useNavigate,Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from "react-responsive-carousel";
 
import { Helmet  } from 'react-helmet-async';

const axios = require('axios').default;    
 

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "& .MuiFormLabel-root":{
        color:"primary"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#ffffff",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });

export const Home=()=>{

    let history = useNavigate ();
    const theme = useTheme();
      const classes = useStyles();
    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);

    const [isloading,setIsloading] = useState(true);
     

    const [open,setOpen] = useState(false)

    const [box1, setBox1] =  useState(false);


    const [cars,setCars] = useState();
    const [size, setSize] = useState(0);
    const [active, setActive] = useState(0);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
      //lists
       


      const [title,setTitle] = useState();


      const [castes,setCastes] = useState([]);  
      const [countries,setCountries] = useState([]);
      const [maritalstatuses,setMaritalstatuses] = useState([]);
      const [professions,setProfessions] = useState([]);


      const [profilefor,setProfilefor] = useState("a");
      const [maritalstatus,setMartialStatus] = useState("a");
      const [caste,setCaste] = useState("a");
      const [country,setCountry] = useState("a");
      const [profCat,setProfCat] = useState("a"); 

      const [ages,setAges] = useState('all');

      const [sortby,setSortby] = useState("relavance");

      const [err,setErr] = useState(0)
 
    useEffect(()=>{
      window.scrollTo(0, 0);
        getData();
    },[])


    

    const getData=async()=> {

      const formData= new FormData();
      formData.append('page','home');
      
       
      axios.post("/web/get_single_metadata",formData ).then(  (res) => {
             
              if(res.data.status ){
                setTitle(res.data.data.seo_title)
              }
            });  
        axios.get("/web/search_profiles" ).then((res)=>{
            if(res.data.status==true){
                setCastes(res.data.castes)
                setCountries(res.data.countries)
                setMaritalstatuses(res.data.maritalstatuses)
                setProfessions(res.data.professions)
                setCars(res.data.slides)
                setIsloading(false)

            }
        });
    }


    const getMeta=()=>{
         
    }


    return (<div>
          {isloading?<Loading/>:<div>
            <Helmet>
  <title>{title}</title>
     
  </Helmet> 
            <div >
             
            {cars && (<Carousel
                 animation="slide"
                 timeout={1200}
                 autoPlay={true}
                 showIndicators={true}
                 showArrows={true}
                 centerMode={true}
                 centerSlidePercentage={size<960?100:65}
                 infiniteLoop
                 stopOnHover
                 swipeable
                 showThumbs={false}
                 transitionTime={1200}
                statusFormatter={(current, total) => {
                  setActive(current - 1);
                  return ``;
                }}
              >
                {cars.map((item, i) => (
                  <div id={`${
                    i === active && `carousel_image_id_active`
                  }`}
                  className="MuiPaper-elevation1"
                  style={{
                    //margin:"5px",
                  //  paddingRight:"5px",
                    borderRadius:"5px",
                    border:"2px solid #c8ac8d",
                     filter: "blur(5px)",
                  }}
                  >
                  
                        <img src={item.url} style={{borderRadius:"5px"}} />
                         
                        
                    
                
              </div>
                ))}
              </Carousel>)}
            </div>
              <div className="container">
                  <Box boxShadow={3} p={3}  m={3} style={{border:"2px solid #bfaa8d",borderRadius:"5px",backgroundColor:"white" }}>
                  <Dialog  onClose={()=>{
                   setOpen(false)
               }} aria-labelledby="alert-dialog-title"
               aria-describedby="alert-dialog-description" open={open} >
                    <DialogTitle className="text-center">! Please Login or Signup</DialogTitle>
                   <DialogContent  style={{ maxHeight:"100vh",overflow:"hidden"}}>
                   <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-start"
                      spacing={3}
                      >
                        <Grid item xs={12}>
                        <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/login"} className="linkstyle"  >LOGIN</Button>
                        </Grid>
                        <Grid item xs={12}>
                        <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/signup"} className="linkstyle"  >Signup</Button>
                        </Grid>
                        <Grid item xs={12}>

                        </Grid>
                        </Grid>
                   </DialogContent>
                   </Dialog>     
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={4}
                    >
                        <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" className={classes.root}
                                    size="small"
                                    
                                    fullWidth  >
                                        <InputLabel >
                                        Looking for *
                                        </InputLabel>
                                    <Select
                                         error={err===1 && true}
                                        value={profilefor}
                                        onChange={(e) => {
                                            
                                        setProfilefor(e.target.value);
                                            
                                        }}
                                        style={{color:theme.palette.primary.main}}
                                        
                                        label="Looking for *"
                                        
                                    >
                                      <MenuItem
                                  key="a"
                                  value="a"
                                  color="primary"
                                >
                                 Select Brides or Grooms
                                </MenuItem>
                                  <MenuItem
                                  key="female"
                                  value="female"
                                  color="primary"
                                >
                                  Brides
                                </MenuItem>     
                                <MenuItem
                                  key="male"
                                  value="male"
                                  color="primary"
                                >
                                  Grooms
                                </MenuItem>
                              
                                         
                                    
                                    </Select>
                                </FormControl> 
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" className={classes.root}
                                    size="small"
                                    
                                    fullWidth  >
                                        <InputLabel >
                                        Marital Status
                                        </InputLabel>
                                    <Select
                                         
                                        value={maritalstatus}
                                        onChange={(e) => {
                                            
                                        setMartialStatus(e.target.value);
                                            
                                        }}
                                        style={{color:theme.palette.primary.main}}
                                        
                                        label="Marital Status"
                                        
                                    >
                                      <MenuItem
                                  key="a"
                                  value="a"
                                  color="primary"
                                >
                                  Select Marital Status
                                </MenuItem>
                                       { maritalstatuses &&
                     maritalstatuses.map((ms) => {
                          
                            return (
                                <MenuItem
                                  key={ms.id}
                                  value={ms.name}
                                  color="primary"
                                >
                                  {ms.name}
                                </MenuItem>
                              );})}
                                         
                                    
                                    </Select>
                                </FormControl> 
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                        <FormControl component="fieldset">
                        <FormLabel component="legend">Age: </FormLabel>
                        <RadioGroup  row aria-label="position" name="position"   value={ages} onChange={(e)=>{
                            setAges(e.target.value)
                             
                        }}>
                            <FormControlLabel value="all" control={<Radio color="primary"/>} label="All Ages" style={{ minWidth: 125 }} />
                            <FormControlLabel value="20s" control={<Radio  color="primary"/>} label="21 - 30" style={{ minWidth: 125 }}/>
                            <FormControlLabel value="30s" control={<Radio  color="primary"/>} label="31 - 40" style={{ minWidth: 125 }} />
                            <FormControlLabel value="40s" control={<Radio  color="primary"/>} label="41 - 50" style={{ minWidth: 125 }} />
                            <FormControlLabel value="50s" control={<Radio  color="primary"/>} label="51 and Above" style={{ minWidth: 125 }} />
                        </RadioGroup>
                        </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" className={classes.root}
                                    size="small"
                                    
                                    fullWidth  >
                                        <InputLabel >
                                        Caste 
                                        </InputLabel>
                                    <Select
                                        // native
                                        value={caste}
                                        
                                        onChange={(e) => {
                                            
                                        setCaste(e.target.value);
                                            
                                        }}
                                        style={{color:theme.palette.primary.main}}
                                        
                                        label="Caste"
                                        
                                    >
                                      <MenuItem
                                  key="a"
                                  value="a"
                                  color="primary"
                                >
                                  Select Caste
                                </MenuItem>
                                       { castes &&
                     castes.map((cas) => {
                          
                            return (
                                <MenuItem
                                  key={cas.id}
                                  value={cas.name}
                                  color="primary"
                                >
                                  {cas.name}
                                </MenuItem>
                              );})}
                                         
                                    
                                    </Select>
                                </FormControl> 
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" className={classes.root}
                                    size="small"
                                    
                                    fullWidth  >
                                        <InputLabel >
                                        Current Country 
                                        </InputLabel>
                                    <Select
                                         
                                        value={country}
                                        onChange={(e) => {
                                            
                                        setCountry(e.target.value);
                                            
                                        }}
                                        style={{color:theme.palette.primary.main}}
                                        
                                        label="Current Country "
                                        
                                    >
                                       <MenuItem
                                  key="a"
                                  value="a"
                                  color="primary"
                                >
                                  Select Country
                                </MenuItem>
                                       { countries &&
                     countries.map((country) => {
                          
                            return (
                                <MenuItem
                                  key={country.id}
                                  value={country.name}
                                  color="primary"
                                >
                                  {country.name}
                                </MenuItem>
                              );})}
                                         
                                    
                                    </Select>
                                </FormControl> 
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" className={classes.root}
                                    size="small"
                                    
                                    fullWidth  >
                                        <InputLabel >
                                        Profession Category  
                                        </InputLabel>
                                    <Select
                                         
                                        value={profCat}
                                        onChange={(e) => {
                                            
                                          setProfCat(e.target.value);
                                           
                                            
                                        }}
                                        style={{color:theme.palette.primary.main}}
                                        
                                        label="Profession Category   "
                                        
                                    >
                                      <MenuItem
                                  key="a"
                                  value="a"
                                  color="primary"
                                >
                                  Select Profession Category
                                </MenuItem>
                                       { professions &&
                     professions.map((profession) => {
                          
                            return (
                                <MenuItem
                                  key={profession.id}
                                  value={profession.name}
                                  color="primary"
                                >
                                  {profession.name}
                                </MenuItem>
                              );})}
                                         
                                    
                                    </Select>
                                </FormControl> 
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" className={classes.root}
                                    size="small"
                                    
                                    fullWidth  >
                                        <InputLabel >
                                        Sort by 
                                        </InputLabel>
                                    <Select
                                         
                                        value={sortby}
                                        onChange={(e) => {
                                            
                                        setSortby(e.target.value);
                                            
                                        }}
                                        style={{color:theme.palette.primary.main}}
                                        
                                        label="Sort by"
                                        
                                    >
                                  <MenuItem
                                  key="relavance"
                                  value="relavance"
                                  color="primary"
                                >
                                  Relavant Profiles
                                </MenuItem>     
                                <MenuItem
                                  key="latest"
                                  value="latest"
                                  color="primary"
                                >
                                  Latest Profiles
                                </MenuItem>
                              
                                         
                                    
                                    </Select>
                                </FormControl> 
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} className="text-center">
                                <Button variant="contained" color="primary" style={{width:'70%'}} onClick={()=>{
                                        setErr(0)
                                        if(profilefor==='a'){
                                          setErr(1)
                                          setSnack({
                                            message: "Please select Profile for..",
                                            type: 'error',
                                            open: true,
                                            direction:"center"
                                          })
                                        }else{
                                          if(user){
                                            history("/searchresults/"+profilefor+"/"+maritalstatus+"/"+caste+"/"+country+"/"+profCat+"/"+ages+"/"+sortby)
                                          }else{
                                            setOpen(true)
                                          }
                                          
                                        }
                                }}  className="linkstyle"    >
                                   Get Profiles
                                </Button>
                        </Grid>
                </Grid>
                </Box>
              </div>
              <br/><br/>
              <div className="container">
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="flex-start"
                spacing={4}
              >
                  <Grid item xs={12} sm={6} md={4} className="text-center">
                  <ButtonBase className={classes.cardshadow}  component={Link} to={"/login"} style={{width:"100%"}}>
                                <Card className={classes.cardshadow} style={{width:"100%"}}>
                                  <CardMedia  
                                         className="text-center"
                                        style={{padding:"10px"}}
                                         
                                        >
                                          <img src="/static/login.png" style={{height:"200px",width:"200px"}} />
                                  </CardMedia>          
                                  <CardContent className="text-center">
                                    <Typography variant="h6" >LOGIN / REGISTER</Typography> 
                                    <hr/>
                                     
                                    <Typography variant="body1"  style={{textAlign:"left"}} ><b style={{textDecoration:"underline"}}>Step 1:</b> Login or Register with <b>Kapu Kalyanalu</b>.</Typography> 
                                     
                                  </CardContent>
                                </Card>
                                </ButtonBase>    
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className="text-center">
                  <ButtonBase   component={Link} to={"/myaccount/profiles"} style={{width:"100%"}}>
                                <Card className={classes.cardshadow} style={{width:"100%"}}>
                                <CardMedia  
                                         className="text-center"
                                        style={{padding:"10px"}}
                                         
                                        >
                                          <img src="/static/addprofile.png" style={{height:"200px",width:"200px"}} />
                                  </CardMedia> 
                                  <CardContent className="text-center">
                                  <Typography variant="h6">CREATE PROFILE</Typography> 
                                  <hr/>
                                  <Typography variant="body1" style={{textAlign:"left"}} ><b style={{textDecoration:"underline"}}>Step 2:</b> Add your <b>Bride or Groom's</b> profile.</Typography> 
                                  </CardContent>
                                </Card>
                              </ButtonBase>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className="text-center">
                  <ButtonBase   component={Link} to={"/searchresults/a/a/a/a/a/relavance"} style={{width:"100%"}}>
                  <Card className={classes.cardshadow} style={{width:"100%"}}>
                                  <CardMedia  
                                         className="text-center"
                                        style={{padding:"10px"}}
                                         
                                        >
                                          <img src="/static/interact.png" style={{height:"200px",width:"200px"}} />
                                  </CardMedia> 
                                  <CardContent className="text-center">
                                  <Typography variant="h6">SEARCH & INTERACT</Typography> 
                                  <hr/>
                                  <Typography variant="body1"  style={{textAlign:"left"}} ><b style={{textDecoration:"underline"}}>Step 3:</b> Search and Interact with the <b>Profile</b> that matches your <b>Requirement</b>.</Typography> 
                                  </CardContent>
                                </Card>
                               </ButtonBase> 
                  </Grid>
                </Grid>
              </div>
              <br/>
              <br/>
          </div>}
    </div>);
}