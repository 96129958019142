import {useState,useEffect,useContext} from 'react';
import {Grid,Container,Box,Button, makeStyles, StepLabel,StepContent,FormControl,InputLabel,Card,CardMedia,Select,MenuItem,Avatar,Slider,DialogActions,DialogContent, Dialog,DialogTitle,Snackbar,TextField,useTheme} from '@material-ui/core';
import {UserContext,SnackbarContext} from './UserContext';
import {BackDropLoad} from './BackdropLoading';
import {Loading } from "./loading";
import {useNavigate,useParams } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PublishIcon from '@material-ui/icons/Publish';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import {Helmet} from 'react-helmet-async';

import Compressor from 'compressorjs';
 
var momentt = require("moment-timezone");
const headKey="asda()*^%~`)sdawqe23123>;p988u12hmmasd";
const axios = require('axios').default;


const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "& .MuiFormLabel-root":{
        color:"primary"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#ffffff",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });

export const EditProfile = ()=>{
    let {id} = useParams();
    let history = useNavigate ();
    const theme = useTheme();
    const classes = useStyles();
    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);

    const [isloading,setIsloading] = useState(true);

    const [activeStep, setActiveStep] =  useState(0);




    //lists
    const [stars,setStars] = useState([]);
    const [castes,setCastes] = useState([]);
    const [countries,setCountries] = useState([]);
    const [profilesfor,setProfilesfor] = useState([]);
    const [maritalstatuses,setMaritalstatuses] = useState([]);
    const [professions,setProfessions] = useState([]);


    //personal information 1 11
    const [profilefor,setProfilefor] = useState("");
    const [maritalstatus,setMartialStatus] = useState("");
    const [caste,setCaste] = useState("");
    const [name,setName] = useState("");
    const [bridegroom,setBridegroom] = useState();
    const [surname,setSurname] = useState("");
    const [email,setEmail] = useState("");
    const [mob,setMob] = useState("");
    const [country,setCountry] = useState("");
    const [height,setHeight] = useState("");
    const [complex,setComplex] = useState("");

    //personal information 2 19
    const [star,setStar] = useState("");
    const [gothram,setGothram] = useState("");
    const [birthplace,setBirthplace] = useState("");
    const [birthtime,setBirthtime] = useState("00:00");
    const [dob,setDob] = useState("");
    const [age,setAge] = useState();
    const [about,setAbout] = useState("");
    const [hobbies,setHobbies] = useState("");

    //family details 24
    const [fathername,setFathername] = useState("");
    const [mothername,setMothername] = useState("");
    const [fatherDesignation,setFatherDesignation] = useState("");
    const [motherDesignation,setMotherDesignation] = useState("");
    const [familyDetails,setFamilyDetails] = useState("");

    //work Details 29
    const [profCat,setProfCat] = useState(""); 
    const [designation,setDesignation] = useState(""); 
    const [currentSal,setCurrentSal] = useState(0); 
    const [currentOrgName,setCurrentOrgName] = useState("");
    const [workDetails,setWorkDetails] = useState("");

    //Education Details 33
    const [majorDegree,setMajorDegree] = useState("");
    const [college,setCollege] = useState("");
    const [yearOfPassing,setYearOfPassing] = useState("");
    const [eduDetails,setEduDetails] = useState("");

    //profilePic and Photos 35
    const [dpic,setDpic] = useState();
    const [olddpic,setOldDpic] = useState();
    
    const [doc,setDoc] = useState();
    const [olddoc,setOldDoc] = useState();

    const [photos,setPhotos] = useState([]);
    const [oldphotos,setOldPhotos] = useState([]);

    const [err,setErr] = useState(0);
    const [wait,setWait] = useState(false);


    const [open,setOpen] = useState(false);

    useEffect(()=>{
      window.scrollTo(0, 0);
        getdata();
    },[])


    let compImage =   (image) => {
      return new Promise((resolve,reject)=>{
        new Compressor(image, {
          quality: 0.8,  
          success: async (compressedResult) => {
            return resolve(compressedResult);
          //   await setImages([...images,{raw:compressedResult,preview:URL.createObjectURL(compressedResult)}])
            
          },
        });
      })
      
    };

    function toFeet(n) {
      var realFeet = ((n*0.393700) / 12);
      var feet = Math.floor(realFeet);
      var inches = Math.round((realFeet - feet) * 12);
      return feet + " feet " + inches + ' inches';
    }



    const finalSubmit2=()=>{
      setOpen(false)
      setWait(true);
      const formData=new FormData();
      formData.append('id',id);
      formData.append('profilefor',profilefor.name);
      formData.append('maritalstatus',maritalstatus);
      formData.append('caste',caste);
      formData.append('name',name);
      formData.append('gender',bridegroom);
      formData.append('surname',surname);
      formData.append('email',email);
      formData.append('mob',mob);
      formData.append('country',country);
      formData.append('height',height);
      formData.append('complex',complex);
      formData.append('star',star);
      formData.append('gothram',gothram);
      formData.append('birthplace',birthplace);
      formData.append('birthtime',birthtime);
      formData.append('dob',dob);
      formData.append('age',age);
      formData.append('about',about);
      formData.append('hobbies',hobbies);
      formData.append('fathername',fathername);
      formData.append('mothername',mothername);
      formData.append('fatherDesignation',fatherDesignation);
      formData.append('motherDesignation',motherDesignation);
      formData.append('familyDetails',familyDetails);
      formData.append('profCat',profCat);
      formData.append('designation',designation);
      formData.append('currentSal',currentSal);
      formData.append('currentOrgName',currentOrgName);
      formData.append('workDetails',workDetails);
      formData.append('majorDegree',majorDegree);
      formData.append('college',college);
      formData.append('yearOfPassing',yearOfPassing);
      formData.append('eduDetails',eduDetails);

      formData.append('olddpic',olddpic);
      formData.append('olddoc',olddoc);
      formData.append('oldphotostext',oldphotos.join(",")); 

      if(dpic){
          formData.append('dpic',dpic.raw);
      }
      if(doc){
        formData.append('doc',doc.raw);
      }
      
      for(var i=0;i<photos.length;i++){
           
        formData.append('photos',photos[i].raw);
      }
       
      axios.post("/web/update_profile",formData ).then((res)=>{
        if(res.data.status===1){
          setSnack({
            message: res.data.msg,
            type: 'success',
            open: true,
            direction:"center"
          });
          history('/myaccount/view_profile/'+id)
        }else if(res.data.status===3){
          setSnack({
              message: res.data.msg,
              type: 'warning',
              open: true,
              direction:"left"
          });
            setUser(null)
            history("/")
      }
      })
    }

    const finalSubmit=(e)=>{
      setErr(0);
      e.preventDefault();
      if(olddpic==null && !dpic){
         
          setErr(29)
          setSnack({
            message: "Please Upload your Profile Photo..",
            type: 'error',
            open: true,
            direction:"center"
          });
      }else if(oldphotos.length+photos.length==0){
        setErr(30)
          setSnack({
            message: "Please Upload atleast one photo..",
            type: 'error',
            open: true,
            direction:"center"
          });
      }else if(olddoc==null && !doc){
         
        setErr(31)
        setSnack({
          message: "Please Upload a Govt Document for Verification..",
          type: 'error',
          open: true,
          direction:"center"
        });
    }else{
        setOpen(true)
      }
    }

    const submitEduWorkdetails=(e)=>{
      setErr(0);
      e.preventDefault();
      if(profCat==''){
        setErr(22)
        setSnack({
          message: "Please Choose Profession Category..",
          type: 'error',
          open: true,
          direction:"center"
        });
      }else if(designation==''){
        setErr(23)
        setSnack({
          message: "Please Enter Current Designation..",
          type: 'error',
          open: true,
          direction:"center"
        });
      }else if(currentOrgName==''){
        setErr(24)
        setSnack({
          message: "Please Enter Current organistion name..",
          type: 'error',
          open: true,
          direction:"center"
        });
      }
      // else if(currentSal==''){
      //   setErr(25)
      //   setSnack({
      //     message: "Please Enter Current Salary in Lakhs per Annum..",
      //     type: 'error',
      //     open: true,
      //     direction:"center"
      //   });
      // }
      else if(majorDegree==''){
        setErr(26)
        setSnack({
          message: "Please Enter your Major Degree..",
          type: 'error',
          open: true,
          direction:"center"
        });
      }else if(college==''){
        setErr(27)
        setSnack({
          message: "Please Enter the name of College or University..",
          type: 'error',
          open: true,
          direction:"center"
        });
      }else if(yearOfPassing==''){
        setErr(28)
        setSnack({
          message: "Please Enter the Year of Passing..",
          type: 'error',
          open: true,
          direction:"center"
        });
      }else{
        setActiveStep(4)
      }
    }  

    const getdata=()=>{
        const formData=new FormData();
        formData.append('id',id);
        axios.post("/web/update_profile",formData ).then((res)=>{
                 
            if(res.data.status===1){
                 
                var proffor;
                for(var i=0;i< res.data.profilesfor.length;i++){     
                    if(res.data.profilesfor[i].name===res.data.data.profilefor){
                        proffor=res.data.profilesfor[i];
                    }
                }
                setProfilefor(proffor)
                setMartialStatus(res.data.data.maritalstatus);
                setCaste(res.data.data.caste);
                setName(res.data.data.name);
                setBridegroom(res.data.data.gender);
                setSurname(res.data.data.surname);
                setEmail(res.data.data.email);
                setMob(res.data.data.mob);
                setCountry(res.data.data.country);
                setHeight(res.data.data.height);
                setComplex(res.data.data.complex);

                setStar(res.data.data.star);
                setGothram(res.data.data.gothram);
                setBirthplace(res.data.data.birthplace);
                setBirthtime(res.data.data.birthtime);
                var dob=String(momentt(res.data.data.dob).format('YYYY-MM-DD'));
                setDob(dob);
                 setAge(res.data.data.age);
                setAbout(res.data.data.about);
                setHobbies(res.data.data.hobbies);

                setFathername(res.data.data.fathername);
                setMothername(res.data.data.mothername);
                setFatherDesignation(res.data.data.fatherDesignation);
                setMotherDesignation(res.data.data.motherDesignation);
                setFamilyDetails(res.data.data.familyDetails);
                setProfCat(res.data.data.profCat); 
                setDesignation(res.data.data.designation); 
                setCurrentSal(res.data.data.currentSal); 
                setCurrentOrgName(res.data.data.currentOrgName);
                setWorkDetails(res.data.data.workDetails);

                setMajorDegree(res.data.data.majorDegree);
                setCollege(res.data.data.college);
                setYearOfPassing(res.data.data.yearOfPassing);
                setEduDetails(res.data.data.eduDetails);

                setOldDpic(res.data.data.dpic)
                setOldDoc(res.data.data.aadhar_link)
                setOldPhotos(res.data.data.photos.split(","))


                setStars(res.data.stars)
                setCastes(res.data.castes)
                setCountries(res.data.countries)
                setProfilesfor(res.data.profilesfor)
                setMaritalstatuses(res.data.maritalstatuses)
                setProfessions(res.data.professions)
                setIsloading(false)
            }else if(res.data.status===3){
                setSnack({
                    message: res.data.msg,
                    type: 'warning',
                    open: true,
                    direction:"left"
                });
                  setUser(null)
                  history("/")
            }
        });
    } 


    const deleteImage=(image)=>{
        setWait(true)
        const formData=new FormData();
        formData.append('id',id);
        formData.append('deleteimage',image);
        formData.append('oldphotos',oldphotos.join(","));
        axios.post("/web/delete_image",formData ).then((res)=>{
            if(res.data.status===1){
                setOldPhotos(res.data.imagesarr)
                setSnack({
                    message: res.data.msg,
                    type: 'success',
                    open: true,
                    direction:"center"
                  });
                setWait(false)
            }else if(res.data.status===3){
                setSnack({
                    message: res.data.msg,
                    type: 'warning',
                    open: true,
                    direction:"left"
                });
                  setUser(null)
                  history("/")
            }
        });
    }

  const  calculate_age = (dob1) => {
      var today = new Date();
      var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
      var age_now = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
      {
          age_now--;
      }
       
      return age_now;
    }

    const submitfamilydetails=(e)=>{
      setErr(0);
      e.preventDefault();
      if(fathername==''){
        setErr(19)
        setSnack({
          message: "Please Enter Father's Name..",
          type: 'error',
          open: true,
          direction:"center"
        });
      }else if(mothername==''){
        setErr(20)
        setSnack({
          message: "Please Enter Mother's Name..",
          type: 'error',
          open: true,
          direction:"center"
        });
      }else if(fatherDesignation==''){
        setErr(21)
        setSnack({
          message: "Please Enter father's Designation..",
          type: 'error',
          open: true,
          direction:"center"
        });
      }else{
        setActiveStep(3)
      }
    }


    const submitcandprof2=(e)=>{
      setErr(0);
      e.preventDefault();
      if(dob==''){
        setErr(11)
        setSnack({
          message: 'Please select Date of Birth..',
          type: 'error',
          open: true,
          direction:"center"
        });
      }else if(age<21){
        setErr(12)
        setSnack({
          message: 'Candidate should be atleast 21 year old..',
          type: 'error',
          open: true,
          direction:"center"
        });
      }else if(birthtime==''){
        setErr(13)
        setSnack({
          message: 'Enter Bith Time..',
          type: 'error',
          open: true,
          direction:"center"
        });
      }else if(star==''){
        setErr(14)
        setSnack({
          message: 'Please select the star sign..',
          type: 'error',
          open: true,
          direction:"center"
        });
      }else if(gothram==''){
        setErr(15)
        setSnack({
          message: 'Please Enter Gothram..',
          type: 'error',
          open: true,
          direction:"center"
        });
      }else if(birthplace==''){
        setErr(16)
        setSnack({
          message: 'Please Enter Place of Birth..',
          type: 'error',
          open: true,
          direction:"center"
        });
      }else if(about==''){
        setErr(17)
        setSnack({
          message: 'Please Enter few lines about the Candidate..',
          type: 'error',
          open: true,
          direction:"center"
        });
      }else if(hobbies==''){
        setErr(18)
        setSnack({
          message: 'Please Enter Hobbies or Interests..',
          type: 'error',
          open: true,
          direction:"center"
        });
      }else{
        setActiveStep(2)
      }    
      
    } 
    
    const submitcandprof=(e)=>{
      setErr(0);
      e.preventDefault();
      
      if(profilefor===''){
        setErr(1)
        setSnack({
          message: 'Please select profile for..',
          type: 'error',
          open: true,
          direction:"center"
        });
      }else if(caste===''){
        setErr(2)
        setSnack({
          message: 'Please select Caste',
          type: 'error',
          open: true,
          direction:"center"
        });
      }
      else if(maritalstatus===''){
        setErr(3)
        setSnack({
          message: 'Please select Martial status',
          type: 'error',
          open: true,
          direction:"center"
        });
      }
      else if(name===''){
        setErr(4)
        setSnack({
          message: 'Please Enter Candidate Name',
          type: 'error',
          open: true,
          direction:"center"
        });
      }else if(surname===''){
        setErr(5)
        setSnack({
          message: 'Please Enter Candidate Surname',
          type: 'error',
          open: true,
          direction:"center"
        });
      }else if(!email.includes('@')){
        setErr(6)
        setSnack({
          message: 'Please Enter a valid Email-ID',
          type: 'error',
          open: true,
          direction:"center"
        });
      }else if(mob.length!=10){
          setErr(7)
          setSnack({
            message: 'Please Enter a valid 10 Digit Mobile number',
            type: 'error',
            open: true,
            direction:"center"
          });
      }else if(country==''){
        setErr(8)
        setSnack({
          message: "Please Select Candidate's Current Country",
          type: 'error',
          open: true,
          direction:"center"
        });
      }else if(height==''){
        setErr(9)
        setSnack({
          message: 'Please Enter Height in Feet.Inches ',
          type: 'error',
          open: true,
          direction:"center"
        });
      }else if(complex==''){
        setErr(10)
        setSnack({
          message: "Please Enter Candidate's Complexion ",
          type: 'error',
          open: true,
          direction:"center"
        });
      }else{
        setActiveStep(1)
      }
    }

    return (<div>
         {name && <Helmet>
  <title>Edit {name} {surname}'s Profile | Kapu Kalyanalu</title>
     
 </Helmet>}
        <Container  
             >
               <Dialog  onClose={()=>{
                   setOpen(false)
               }} aria-labelledby="simple-dialog-title" open={open}>
                    <DialogTitle>! Please Confirm</DialogTitle>
                   <DialogContent>
                   By Re-Submitting with or without Changes, your Profile's Status will change to <b>'Waiting for Approval'</b>.
                   </DialogContent>
                   <DialogActions>
                       <Button  variant="contained" color="primary" onClick={finalSubmit2}>
                           Okay, Submit
                       </Button>
                       <Button onClick={()=>setOpen(false)} variant="contained" color="primary">
                           Don't Submit
                       </Button>
                   </DialogActions>
               </Dialog>
                <BackDropLoad wait={wait}/>
                 
       {isloading?<Loading/>:( <Box  boxShadow={3} p={3}  style={{border:"2px solid #bfaa8d",borderRadius:"5px",marginBottom:"10%",backgroundImage:"url('/static/backgrounds/profilebg.jpg')" }}>
          
                <h3 className='text-center'> Update Profile </h3>
             <hr/>
            <Stepper activeStep={activeStep} orientation="vertical">
                <Step key="Candidate Information">
                    <StepLabel  > <u style={{fontSize:activeStep===0?"20px":"15px"}}>Candidate Information</u> </StepLabel>
                    <StepContent>
                        <br/>
                        <form onSubmit={submitcandprof}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={4}
                        >
                            <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" className={classes.root}
                                    size="small"
                                    
                                    fullWidth  >
                                        <InputLabel >
                                        Profile for *
                                        </InputLabel>
                                    <Select
                                        // native
                                        error={err==1 && true}
                                        value={profilefor}
                                        onChange={(e) => {
                                            
                                          setProfilefor(e.target.value);
                                          setBridegroom(e.target.value.bg)
                                            
                                        }}
                                        style={{color:theme.palette.primary.main}}
                                        
                                        label="Profile for *"
                                        
                                    >
                                       { profilesfor &&
                     profilesfor.map((profile) => {
                          
                            return (
                                <MenuItem
                                  key={profile}
                                  value={profile}
                                  color="primary"
                                >
                                  {profile.name}
                                </MenuItem>
                              );})}
                                         
                                    
                                    </Select>
                                </FormControl> 
                            </Grid>
                            
                            {bridegroom &&<Grid item xs={12} sm={6} style={{textAlign:"left !important"}} >
                              <h5 className='text-left'>Gender: {bridegroom}</h5>
                            </Grid> }
                            <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" className={classes.root}
                                    size="small"
                                    
                                    fullWidth  >
                                        <InputLabel >
                                        Caste *
                                        </InputLabel>
                                    <Select
                                        // native
                                        value={caste}
                                        error={err==2 && true}
                                        onChange={(e) => {
                                            
                                        setCaste(e.target.value);
                                            
                                        }}
                                        style={{color:theme.palette.primary.main}}
                                        
                                        label="Caste *"
                                        
                                    >
                                       { castes &&
                     castes.map((cas) => {
                          
                            return (
                                <MenuItem
                                  key={cas.id}
                                  value={cas.name}
                                  color="primary"
                                >
                                  {cas.name}
                                </MenuItem>
                              );})}
                                         
                                    
                                    </Select>
                                </FormControl> 
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" className={classes.root}
                                    size="small"
                                    
                                    fullWidth  >
                                        <InputLabel >
                                        Marital Status *
                                        </InputLabel>
                                    <Select
                                        // native
                                        error={err==3 && true}
                                        value={maritalstatus}
                                        onChange={(e) => {
                                            
                                        setMartialStatus(e.target.value);
                                            
                                        }}
                                        style={{color:theme.palette.primary.main}}
                                        
                                        label="Marital Status *"
                                        
                                    >
                                       { maritalstatuses &&
                     maritalstatuses.map((ms) => {
                          
                            return (
                                <MenuItem
                                  key={ms.id}
                                  value={ms.name}
                                  color="primary"
                                >
                                  {ms.name}
                                </MenuItem>
                              );})}
                                         
                                    
                                    </Select>
                                </FormControl> 
                            </Grid>
                         <Grid item xs={12} sm={6}>
                         <TextField id="outlined-basic" label="Name *" variant="outlined"  className={classes.root}
                                error={err===4 && true}
                                value={name}
                                
                                onChange={(e)=>{
                                    setName(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                
                                />
                         </Grid>
                         <Grid item xs={12} sm={6}>
                         <TextField id="outlined-basic" label="Surname *" variant="outlined"  className={classes.root}
                                error={err===5 && true}
                                value={surname}
                                
                                onChange={(e)=>{
                                    setSurname(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                
                                />
                         </Grid>   
                         <Grid item xs={12} sm={6}>
                         <TextField id="outlined-basic" label="Contact Email-ID *" variant="outlined"  className={classes.root}
                                error={err===6 && true}
                                value={email}
                                
                                onChange={(e)=>{
                                    setEmail(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                
                                />
                         </Grid>
                         <Grid item xs={12} sm={6}>
                         <TextField id="outlined-basic" label="Contact Mobile Number *" variant="outlined"  className={classes.root}
                                error={err===7 && true}
                                value={mob}
                                type="number"
                                onChange={(e)=>{
                                    setMob(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                
                                />
                         </Grid>
                         <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" className={classes.root}
                                    size="small"
                                    
                                    fullWidth  >
                                        <InputLabel >
                                        Current Country *
                                        </InputLabel>
                                    <Select
                                        // native
                                        error={err==8 && true}
                                        value={country}
                                        onChange={(e) => {
                                            
                                        setCountry(e.target.value);
                                            
                                        }}
                                        style={{color:theme.palette.primary.main}}
                                        
                                        label="Current Country *"
                                        
                                    >
                                       { countries &&
                     countries.map((country) => {
                          
                            return (
                                <MenuItem
                                  key={country.id}
                                  value={country.name}
                                  color="primary"
                                >
                                  {country.name}
                                </MenuItem>
                              );})}
                                         
                                    
                                    </Select>
                                </FormControl> 
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Slider value={height}
                              valueLabelDisplay="on"
                              
                              aria-labelledby="range-slider"
                              onChange={(e,newValue)=>{
                                
                                setHeight(newValue);
                              }}
                              valueLabelFormat={(value)=>{
                                  return   `${parseInt(value)}cm`;
                              }}

                              min={120}
                              max={200}
                              >

                              </Slider>
                              <div>
                                Height in Feet Inches: {toFeet(height)}
                              </div>
                         {/* <TextField id="outlined-basic" label="Height in Feet and Inches *" variant="outlined"  className={classes.root}
                                error={err===9 && true}
                                value={height}
                                type="number"
                                onChange={(e)=>{
                                    setHeight(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder='Ex: 5.11 for Five Feet & 11 inch'
                                /> */}
                         </Grid>
                         <Grid item xs={12} sm={6}>
                         <TextField id="outlined-basic" label="Complexion *" variant="outlined"  className={classes.root}
                                error={err===10 && true}
                                value={complex}
                                
                                onChange={(e)=>{
                                    setComplex(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                
                                />
                         </Grid>
                         <br/>
                         <Grid item xs={12} className='text-center'>
                         <Button variant="contained" color="primary" style={{width:'50%'}}  className="linkstyle"  type="submit" disabled={wait} >
                                 Save & Proceed&nbsp;<NavigateNextIcon/>
                         </Button>
                         </Grid>
                    </Grid>     
                    </form>   
                    </StepContent>
                </Step>    
                <Step key="Candidate Information (Continuation)">
                    <StepLabel><u style={{fontSize:activeStep===1?"20px":"15px"}}>Candidate Information (Continuation)</u></StepLabel>
                    <StepContent>
                    <br/>
                        <form onSubmit={submitcandprof2}>
                      <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={4}
                          >
                             <Grid item xs={12} sm={6}>
                             <TextField
                                id="date"
                                className={classes.root}
                                style={{width:"100%"}}
                                label="Date of Birth *"
                                type="date"
                                value={dob}
                                error={err===11 && true}
                                onChange={(e)=>{
                                   
                                  setDob(e.target.value)
                                  setAge(calculate_age(e.target.value))
                                }}
                                className={classes.textField}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                             </Grid>
                             {age>0 && <Grid item xs={12} sm={6}>
                             <h5 >Age: <span style={{color:age<21?"red":"green"}}>{age}</span></h5>
                             </Grid> }

                             <Grid item xs={12} sm={6}>
                             <TextField
                                          id="time"
                                          label="Time of Birth *"
                                          type="time"
                                          error={err===13 && true}
                                          className={classes.root}
                                          style={{width:"100%"}}
                                          defaultValue="12:00"
                                          value={birthtime}
                                          onChange={(e)=>{
                                            setBirthtime(e.target.value)
                                             
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          inputProps={{
                                            step: 300, // 5 min
                                          }}
                               />                      
                             </Grid>  
                             <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" className={classes.root}
                                    size="small"
                                    
                                    fullWidth  >
                                        <InputLabel >
                                        Star Sign *
                                        </InputLabel>
                                    <Select
                                        // native
                                        value={star}
                                        error={err==14 && true}
                                        onChange={(e) => {
                                            
                                        setStar(e.target.value);
                                            
                                        }}
                                        style={{color:theme.palette.primary.main}}
                                        
                                        label="Star Sign *"
                                        
                                    >
                                       { stars &&
                     stars.map((star) => {
                          
                            return (
                                <MenuItem
                                  key={star.id}
                                  value={star.name}
                                  color="primary"
                                >
                                  {star.name}
                                </MenuItem>
                              );})}
                                         
                                    
                                    </Select>
                                </FormControl> 
                            </Grid>
                            <Grid item xs={12} sm={6}>
                         <TextField id="outlined-basic" label="Gothram *" variant="outlined"  className={classes.root}
                                error={err===15 && true}
                                value={gothram}
                                
                                onChange={(e)=>{
                                    setGothram(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                
                                />
                         </Grid>
                         <Grid item xs={12} sm={6}>
                         <TextField id="outlined-basic" label="Place of Birth *" variant="outlined"  className={classes.root}
                                error={err===16 && true}
                                value={birthplace}
                                
                                onChange={(e)=>{
                                    setBirthplace(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                
                                />
                         </Grid>
                         <Grid item xs={12} sm={6}>
                         <TextField id="outlined-basic" label="About *" variant="outlined"  className={classes.root}
                                error={err===17 && true}
                                value={about}
                                placeholder='Write Few Lines about the Candidate'
                                onChange={(e)=>{
                                    setAbout(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                multiline
                                rows={4}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                />
                         </Grid>
                         <Grid item xs={12} sm={6}>
                         <TextField id="outlined-basic" label="Hobbies *" variant="outlined"  className={classes.root}
                                error={err===18 && true}
                                value={hobbies}
                                placeholder="Candidate's Hobbies and Interests"
                                onChange={(e)=>{
                                    setHobbies(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                multiline
                                rows={4}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                />
                         </Grid>
                         <br/>
                         <Grid item xs={12}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              spacing={4}
                              >
                                
                                <Grid item xs={12} sm={12} md={6} className='text-center'>
                                <Button variant="contained" color="primary" style={{width:'50%'}} onClick={()=>{setActiveStep(0) }} className="linkstyle"   disabled={wait} >
                                <ArrowBackIosIcon/>&nbsp;Go Back 
                                </Button>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} className='text-center'>
                                <Button variant="contained" color="primary" style={{width:'50%'}} className="linkstyle"   type="submit" disabled={wait} >
                                        Save & Proceed&nbsp;<NavigateNextIcon/>
                                </Button>
                                </Grid>
                            </Grid>
                         </Grid>
                        </Grid>
                          </form>  
                    </StepContent>
                </Step>
                <Step key="Professional Details">
                    <StepLabel><u style={{fontSize:activeStep===2?"20px":"15px"}}>Family Details</u></StepLabel>
                    <StepContent>
                    <br/>
                      <form onSubmit={submitfamilydetails}>
                      <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={4}
                          >
                          <Grid item xs={12} sm={6}>
                         <TextField id="outlined-basic" label="Father Name *" variant="outlined"  className={classes.root}
                                error={err===19 && true}
                                value={fathername}
                                
                                onChange={(e)=>{
                                    setFathername(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                
                                />
                         </Grid>
                         <Grid item xs={12} sm={6}>
                         <TextField id="outlined-basic" label="Mother Name *" variant="outlined"  className={classes.root}
                                error={err===20 && true}
                                value={mothername}
                                
                                onChange={(e)=>{
                                    setMothername(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                
                                />
                         </Grid>
                         <Grid item xs={12} sm={6}>
                         <TextField id="outlined-basic" label="Father's Designation *" variant="outlined"  className={classes.root}
                                error={err===21 && true}
                                value={fatherDesignation}
                                
                                onChange={(e)=>{
                                    setFatherDesignation(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                
                                />
                         </Grid>
                         <Grid item xs={12} sm={6}>
                         <TextField id="outlined-basic" label="Mother's Designation " variant="outlined"  className={classes.root}
                                 
                                value={motherDesignation}
                                
                                onChange={(e)=>{
                                    setMotherDesignation(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                
                                />
                         </Grid>
                         <Grid item xs={12} sm={6}>
                         <TextField id="outlined-basic" label="Family Details" variant="outlined"  className={classes.root}
                                 
                                value={familyDetails}
                                placeholder="Details about Siblings and other details..."
                                onChange={(e)=>{
                                    setFamilyDetails(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                multiline
                                rows={4}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                />
                         </Grid>
                            <br/>
                         <Grid item xs={12}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              spacing={4}
                              >
                                <Grid item xs={12} sm={12} md={6} className='text-center'>
                                <Button variant="contained" color="primary" style={{width:'50%'}} onClick={()=>{setActiveStep(1) }} className="linkstyle"   disabled={wait} >
                                <ArrowBackIosIcon/>&nbsp;Go Back 
                                </Button>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} className='text-center'>
                                <Button variant="contained" color="primary" style={{width:'50%'}}  className="linkstyle"  type="submit" disabled={wait} >
                                        Save & Proceed&nbsp;<NavigateNextIcon/>
                                </Button>
                                </Grid>
                            </Grid>
                         </Grid>
                      </Grid>
                      </form>  
                    </StepContent>
                </Step> 
                <Step key="Profession & Education Details">
                    <StepLabel><u style={{fontSize:activeStep===3?"20px":"15px"}}>Profession & Education Details</u></StepLabel>
                    <StepContent>
                    <br/>
                      <form onSubmit={submitEduWorkdetails}>
                      <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={4}
                          >
                            <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" className={classes.root}
                                    size="small"
                                    
                                    fullWidth  >
                                        <InputLabel >
                                        Profession Category *
                                        </InputLabel>
                                    <Select
                                        // native
                                        error={err==22 && true}
                                        value={profCat}
                                        onChange={(e) => {
                                            
                                          setProfCat(e.target.value);
                                           
                                            
                                        }}
                                        style={{color:theme.palette.primary.main}}
                                        
                                        label="Category *"
                                        
                                    >
                                       { professions &&
                     professions.map((profession) => {
                          
                            return (
                                <MenuItem
                                  key={profession.id}
                                  value={profession.name}
                                  color="primary"
                                >
                                  {profession.name}
                                </MenuItem>
                              );})}
                                         
                                    
                                    </Select>
                                </FormControl> 
                            </Grid>
                            <Grid item xs={12} sm={6}>
                         <TextField id="outlined-basic" label="Current Designation *" variant="outlined"  className={classes.root}
                                error={err===23 && true}
                                value={designation}
                                
                                onChange={(e)=>{
                                    setDesignation(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                
                                />
                         </Grid>
                            <Grid item xs={12} sm={6}>
                         <TextField id="outlined-basic" label="Current Organisation *" variant="outlined"  className={classes.root}
                                error={err===24 && true}
                                value={currentOrgName}
                                
                                onChange={(e)=>{
                                    setCurrentOrgName(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                
                                />
                         </Grid>
                         <Grid item xs={12} sm={6}>
                         <Slider value={currentSal}
                              valueLabelDisplay="on"
                              
                              aria-labelledby="range-slider"
                              onChange={(e,newValue)=>{
                                
                                setCurrentSal(newValue);
                              }}
                              valueLabelFormat={(value)=>{
                                  return   `${parseInt(value)} L`;
                              }}

                              min={0}
                              max={99}
                              >

                              </Slider>
                              <div>
                                Salary in Lakhs per year or Annum: {currentSal} Lakhs
                              </div>
                         {/* <TextField id="outlined-basic" label="Current Salary in Lakhs per Annum *" variant="outlined"  className={classes.root}
                                error={err===25 && true}
                                value={currentSal}
                                type="number"
                                onChange={(e)=>{
                                    setCurrentSal(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder='Ex: 2.5 Lakhs per annum'
                                /> */}
                         </Grid>
                         <Grid item xs={12} sm={6}>
                         <TextField id="outlined-basic" label="Work Details" variant="outlined"  className={classes.root}
                                 
                                value={workDetails}
                                placeholder="Previous Work details..."
                                onChange={(e)=>{
                                    setWorkDetails(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                multiline
                                rows={4}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                />
                         </Grid>
                         <Grid item xs={12} sm={6}></Grid>
                         <Grid item xs={12} sm={6}>
                         <TextField id="outlined-basic" label="Major Degree *" variant="outlined"  className={classes.root}
                                error={err===26 && true}
                                value={majorDegree}
                                
                                onChange={(e)=>{
                                    setMajorDegree(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                
                                />
                         </Grid>
                         <Grid item xs={12} sm={6}>
                         <TextField id="outlined-basic" label="University / College Name *" variant="outlined"  className={classes.root}
                                error={err===27 && true}
                                value={college}
                                
                                onChange={(e)=>{
                                    setCollege(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                
                                />
                         </Grid>
                         <Grid item xs={12} sm={6}>
                         <TextField id="outlined-basic" label="Year of Passing *" variant="outlined"  className={classes.root}
                                error={err===28 && true}
                                value={yearOfPassing}
                                
                                onChange={(e)=>{
                                    setYearOfPassing(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                
                                />
                         </Grid>
                         <Grid item xs={12} sm={6}>
                         <TextField id="outlined-basic" label="Other Education Details" variant="outlined"  className={classes.root}
                                 
                                value={eduDetails}
                                placeholder="Previous Education Details..."
                                onChange={(e)=>{
                                    setEduDetails(e.target.value);
                                    
                                }}
                                size='small'
                                fullWidth  
                                multiline
                                rows={4}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                />
                         </Grid>
                          <br/>
                         <Grid item xs={12}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              spacing={4}
                              >
                                <Grid item xs={12} sm={12} md={6} className='text-center'>
                                <Button variant="contained" color="primary" style={{width:'50%'}} onClick={()=>{setActiveStep(2) }}  className="linkstyle"  disabled={wait} >
                                <ArrowBackIosIcon/>&nbsp;Go Back 
                                </Button>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} className='text-center'>
                                <Button variant="contained" color="primary" style={{width:'50%'}} className="linkstyle"   type="submit" disabled={wait} >
                                        Save & Proceed&nbsp;<NavigateNextIcon/>
                                </Button>
                                </Grid>
                            </Grid>
                         </Grid>
                      </Grid>
                      </form>      
                    </StepContent>
                </Step> 
                <Step key="Profile Pic and Photos">
                    <StepLabel><u style={{fontSize:activeStep===4?"20px":"15px"}}>Profile Pic and Photos</u></StepLabel>
                    <StepContent>
                    <br/>
                    
                      <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={4}
                          >
                            <Grid item xs={12} sm={6}>
                            <input accept="image/*" 
                         
                        style={{display:"none"}} 
                        onChange={ async(e)=>{
                            if(e.target.files.length!=0){
                              const image=e.target.files[0];
                              var img= await compImage(image); 
                              setDpic({raw:img,preview:URL.createObjectURL(img)})
                              
                          }
                          e.target.value=""
                        }}
                        id="contained-button-file1"
                         
                        type="file"
                        />
                        <label htmlFor="contained-button-file1">
                      <Button  variant="outlined" color="primary" component="span">
                              <AccountCircleIcon/>&nbsp;&nbsp;Upload Profile Photo
                            </Button>
                      </label>
                      {err===29 && (<span style={{color:"red"}}><br/><br/>Please Upload Candidate's Profile Photo.</span>)}
                      {olddpic &&<>
                                <Avatar alt={olddpic} src={"/send_file/profilepics/"+olddpic}  style={{height:"150px",width:"150px",margin:"10px"}}/> 
                             <p style={{color:"green" }}> Upload a New Profile pic to Replace it.</p></> }
                            {dpic && <Avatar alt={dpic.preview} src={dpic.preview}  style={{height:"150px",width:"150px",margin:"10px"}}/>   }
                            </Grid>
                             
                            
                    <Grid item xs={12} sm={6}>
                    <input accept="image/*" 
                       
                        style={{display:"none"}} 
                        onChange={ async(e)=>{
                            if(e.target.files.length!=0){
                              
                              if(oldphotos.length+photos.length+e.target.files.length<=3){
                                  
                                  var arr=[];
                                  for(var i=0;i<e.target.files.length;i++){
                                      var type= e.target.files[i].type;
                                      if(type==="image/jpeg" || type==="image/jpg" ||type==="image/png" ){
                                        const image=e.target.files[i];
                                        var img= await compImage(image);  
                                        arr.push({raw:img,preview:URL.createObjectURL(img)});  
                                      }else{
                                          alert("Please select only JPEG, JPG, PNG Images..")
                                      }
                                  }
                                  setPhotos([...photos,...arr]); 
                                  
                              }else{
                                  alert("Maximum Image limit is 3.");
                                  
                              }
                          }
                          e.target.value=""
                        }}
                        id="contained-button-file"
                        multiple
                        type="file"
                        />
                      <label htmlFor="contained-button-file">
                      <Button variant="outlined" color="primary" component="span" style={{margin:"10px"}}>
                              <PublishIcon/>&nbsp;&nbsp;Upload Photos
                            </Button>
                      </label>
                      {err===30 && (<span style={{color:"red"}}><br/><br/>Please Choose atleast one Photo.</span>)}
                      {oldphotos.length!==0 && (<> <Grid item xs={12}>
                        
                        <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={3}
                        >
                           
                       {oldphotos.map((photo)=>{
                           
                           return <Grid item>
                               <Card className={classes.cardshadow}  style={{ backgroundColor:theme.palette.primary.main}}>
                                
                               <CardMedia  
                                        style={{height: 150,width:150}}
                                        image={"/send_file/photos/"+photo}
                                        title={photo} 
                                        />  
                                       
                                       {oldphotos.length!==1 && ( <Button variant="contained" color="primary" component="span" style={{ border:"1px solid black"}} fullWidth onClick={()=>{
                                            //   delete_image(photo);
                                            deleteImage(photo)
                                        }}>
                               DELETE IMAGE
                            </Button>)}
                               </Card>
                               </Grid>
                       })}
                       </Grid>
                    </Grid> <p style={{color:"green" }}>Already Uploaded Photos...</p></>)}
                            {(photos.length!==0  ) ? (<Grid item xs={12} sm={6}>
        
                              <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                                spacing={3}
                                >
                                  {photos.map((image)=>{
                           
                                    return <Grid item>
                                        <Card className={classes.cardshadow} style={{ backgroundColor:theme.palette.primary.main}}>
                                        <CardMedia
                                                  style={{height: 150,width:150}}
                                                  image={image.preview}
                                                  title={image.preview} 
                                                  />  
                                                  <Button variant="contained" color="primary" component="span" style={{ border:"1px solid black"}} fullWidth onClick={()=>{
                                                      
                                                      setPhotos(photos.filter(item => item.preview !== image.preview));
                                                      
                                                  }}>
                                        DELETE IMAGE
                                      </Button>
                                        </Card>
                                        </Grid>
                                })}
                              </Grid>
                              
                            </Grid>):""}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                            <input accept="image/*" 
                         
                        style={{display:"none"}} 
                        onChange={ async(e)=>{
                            if(e.target.files.length!=0){
                              const image=e.target.files[0];
                              var img= await compImage(image); 
                              setDoc({raw:img,preview:URL.createObjectURL(img)})
                              
                          }
                          e.target.value=""
                        }}
                        id="contained-button-file5"
                         
                        type="file"
                        />
                        <label htmlFor="contained-button-file5">
                      <Button  variant="outlined" color="primary" component="span">
                              <InsertDriveFileIcon/>&nbsp;&nbsp;Upload a Document for Verification
                            </Button>
                      </label>
                      {err===31 && (<span style={{color:"red"}}><br/><br/>Please Upload a Govt Document for Verification like - Aadhar or Pan or passport etc. </span>)}
                      {olddoc &&<>
                                <Avatar alt={olddoc} src={"/send_file/doc/"+olddoc}  style={{height:"150px",width:"150px",margin:"10px"}}/> 
                             <p style={{color:"green" }}> Upload a New Verification Document to Replace it.<br/>
                             Govt Document like - Aadhar or Pan or passport etc in Image Format is Required for Profile Verfication.</p></> }
                            {doc && <Avatar alt={doc.preview} src={doc.preview}  style={{height:"150px",width:"150px",margin:"10px"}}/>   }
                            </Grid>            
                   
                            <br/>
                         <Grid item xs={12}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              spacing={4}
                              >
                                <Grid item xs={12} sm={12} md={6}  className='text-center'>
                                <Button variant="contained" color="primary" style={{width:'50%'}} onClick={()=>{setActiveStep(3) }} className="linkstyle"   disabled={wait} >
                                <ArrowBackIosIcon/>&nbsp;Go Back 
                                </Button>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} className='text-center'>
                                <Button variant="contained" color="primary" style={{width:'50%'}}  onClick={ finalSubmit} className="linkstyle"   disabled={wait} >
                                        Submit&nbsp;<NavigateNextIcon/>
                                </Button>
                                </Grid>
                            </Grid>
                         </Grid>
                      </Grid>      
                    </StepContent>
                </Step>
            </Stepper>    
            
             
    
        </Box>)}
        </Container>
         
    </div>);
}