import {useEffect,useState,useContext} from "react";
 
 import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container ,useTheme,Box,Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet  } from 'react-helmet-async';

import {withStyles} from '@material-ui/core/styles';

import {UserContext,SnackbarContext} from './UserContext';

export const MyProfile=()=>{

    const theme=useTheme();
    const {user, setUser} = useContext(UserContext);

    useEffect(()=>{
        window.scrollTo(0, 0);
         
      },[])




    return (<div>

        <Helmet>
         <title>Account Details | Kapu Kalyanalu</title>
            
         </Helmet> <Container maxWidth="md">
       <Box boxShadow={3} p={3}  style={{ border:"2px groove #bfaa8d",backgroundColor:"white",  marginBottom:"5%",borderRadius:"10px",backgroundImage:"url('/static/backgrounds/bg1.jpg')" }}> 
       <Typography variant="h4" className="text-center"   >
                                            Account Details          
                               </Typography>
                               <hr  />
                               <br/>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-evenly"
                          alignItems="center"
                          spacing={3}
                        >
                          <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body1"   gutterBottom   >
                            <b>Name:</b>&nbsp;&nbsp;{user.user_gender} {user.user_name} 
                           </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body1"   gutterBottom   >
                            <b>Mobile no:</b>&nbsp;&nbsp;{user.user_mob}   
                           </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body1"   gutterBottom   >
                            <b>Email-ID:</b>&nbsp;&nbsp;{user.user_email} 
                           </Typography>
                          </Grid>
                        </Grid>   
        </Box>
        </Container>

    </div>)

}