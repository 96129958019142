import {useState,useContext} from 'react';
import {AppBar,Toolbar,Grid, Typography,Button,Container,Hidden,Drawer,List,ListItem,makeStyles,Menu,MenuItem,useTheme, Collapse,DialogContent,Dialog,DialogTitle} from '@material-ui/core';
import { UserContext,SnackbarContext } from './UserContext';
import {BackDropLoad} from "./BackdropLoading";
import {Link,useNavigate} from "react-router-dom";
import PersonIcon from '@material-ui/icons/Person';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PeopleIcon from '@material-ui/icons/People';
import PhoneIcon from '@material-ui/icons/Phone';
import InfoIcon from '@material-ui/icons/Info'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import { useScrollTrigger } from "@material-ui/core";

const axios = require('axios').default;
const headKey="asda()*^%~`)sdawqe23123>;p988u12hmmasd";

const $ = require("jquery");

const useStyles = makeStyles({
    MuiDrawer: {
      backgroundColor: "#EAE7DC"
    }
  });

export const WebNavbar=(props)=>{
    const theme = useTheme();
    const history=useNavigate();
    const classes = useStyles();
    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);
    const [wait,setWait] = useState(false);

    const [open,setOpen] = useState(false);

    const [openDraw, setOpenDraw] = useState(false);
    const [showSideMyAccMenu,setShowSideMyAccMenu] = useState(true);



    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: props.window ? window() : undefined
      });


    $(document).click(function(e){

     
        if($(e.target).closest('#accbuttontop').length != 0){
          return false;
        }else{
          setShowSideMyAccMenu(true)
          //$('#accmenutop').hide();
        }
    
         
         
        
     });

     $("#accbuttontop").hover(function(e){
        setShowSideMyAccMenu(false)
       });
    

    const logout=()=>{
        setWait(true)
        axios.post( "/web/logout", {
            headers:{headKey:headKey}
         }).then((res) => {
            if(res.data.status){
              setUser(null);
              setWait(false);
             history('/');
             setSnack({
                message: res.data.msg,
                type: 'success',
                open: true,
                direction:"left"
              });
            } 
         }); 
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setOpenDraw(open);
      };

    return (<AppBar position="sticky" color={trigger?"third":"transparent"}  elevation={trigger?3:0}
                style={{transition:trigger?"0.3s":"0.5s"}}
    >
        <Container  >
        <Dialog  onClose={()=>{
                   setOpen(false)
               }} aria-labelledby="alert-dialog-title"
               aria-describedby="alert-dialog-description" open={open} >
                    <DialogTitle className="text-center">! Please Login or Signup</DialogTitle>
                   <DialogContent  style={{ maxHeight:"100vh",overflow:"hidden"}}>
                   <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-start"
                      spacing={3}
                      >
                        <Grid item xs={12}>
                        <Button variant="contained"   fullWidth={true} color="primary"   className="linkstyle" onClick={()=>{
                            setOpen(false)
                            history("/login")
                        }} >LOGIN</Button>
                        </Grid>
                        <Grid item xs={12}>
                        <Button variant="contained"   fullWidth={true} color="primary"   className="linkstyle" onClick={()=>{
                            setOpen(false)
                            history("/signup")
                        }} >Signup</Button>
                        </Grid>
                        <Grid item xs={12}>

                        </Grid>
                        </Grid>
                   </DialogContent>
                   </Dialog>  
         <BackDropLoad wait={wait}/>
         <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
              <Grid item xl={3} lg={3} md={3} sm={6} xs={6}  >
              <Link to="/"    className="linkstyle"  title="KapuKalyanalu">
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item className="text-center"> 
                <img
                    style={{height:"100px" ,padding:"15px"}}
                    src={
                        "/static/kklogo.png"
                    }
                alt="Kapu Kalyanalu Logo"
                        />
                     <Hidden lgUp><h5  >Kapu Kalyanalu</h5></Hidden>
                </Grid>
                <Hidden mdDown><Grid item><h5  >
                 &nbsp;Kapu Kalyanalu
                  </h5></Grid></Hidden>

              </Grid>
                    </Link>
              </Grid>
              <Hidden lgUp>
              <Button  variant="contained" color="primary" onClick={toggleDrawer(true)} style={{border:"1px solid black"}}>
                <MenuIcon   />
              </Button>
              <Drawer anchor={"left"} open={openDraw} onClose={toggleDrawer(false)} classes={{paper: classes.MuiDrawer}}>
              <List component="nav"  aria-label="contacts" style={{width:"250px",backgroundColor:"#EAE7DC",color:"black"}}>
                 
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={4}
                >
                    <Grid item></Grid>
                    <Grid item>
                    <ListItem button className="linkstyle" style={{width:"100%"}}   onClick={()=>{
                        setOpenDraw(false)
                        
                        if(user){
                            history("/searchresults/a/a/a/a/a/all/relavance")
                        }else{
                            setOpen(true);
                        }
                    }}>
                                <Grid
                                container
                                direction="row"
                                justify="space-evenly"
                                alignItems="center"
                                >
                                    <Grid item>
                                        <PeopleIcon />
                                    </Grid>
                                    <Grid item>
                                    <Typography component="h5" style={{textTransform: "capitalize"}}  > 
                                    &nbsp;Search Profiles
                                    </Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>
                    </Grid>
                    <Grid item>
                    <ListItem button className="linkstyle"  component={Link} to={"/about-us"} onClick={toggleDrawer(false)}>
                                <Grid
                                container
                                direction="row"
                                justify="space-evenly"
                                alignItems="center"
                                >
                                    <Grid item>
                                        <InfoIcon />
                                    </Grid>
                                    <Grid item>
                                    <Typography component="h5" style={{textTransform: "capitalize"}}  > 
                                    &nbsp;About us
                                    </Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>
                    </Grid>
                    <Grid item>
                    <ListItem button className="linkstyle"  component={Link} to={"/contact-us"} onClick={toggleDrawer(false)}>
                                <Grid
                                container
                                direction="row"
                                justify="space-evenly"
                                alignItems="center"
                                >
                                    <Grid item>
                                        <PhoneIcon />
                                    </Grid>
                                    <Grid item>
                                    <Typography component="h5" style={{textTransform: "capitalize"}}  > 
                                    &nbsp;Contact us
                                    </Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>
                    </Grid>
                    {user?<Grid item>
                    <ListItem button className="linkstyle"  component={Link} to={"/myaccount/profile"} onClick={toggleDrawer(false)}>
                                <Grid
                                container
                                direction="row"
                                justify="space-evenly"
                                alignItems="center"
                                >
                                    <Grid item>
                                        <AccountCircleIcon />
                                    </Grid>
                                    <Grid item>
                                    <Typography component="h5" style={{textTransform: "capitalize"}}  > 
                                    &nbsp;My Profile
                                    </Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>
                    </Grid>:<Grid item>
                    <ListItem button className="linkstyle"  component={Link} to={"/login"} onClick={toggleDrawer(false)}>
                                <Grid
                                container
                                direction="row"
                                justify="space-evenly"
                                alignItems="center"
                                >
                                    <Grid item>
                                        <ExitToAppIcon />
                                    </Grid>
                                    <Grid item>
                                    <Typography component="h5" style={{textTransform: "capitalize"}}  > 
                                    &nbsp;Login
                                    </Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>
                    </Grid>}
                    {user?<Grid item>
                    <ListItem button className="linkstyle"  onClick={()=>{
                        setOpenDraw(false)
                        logout();
                    }}>
                                <Grid
                                container
                                direction="row"
                                justify="space-evenly"
                                alignItems="center"
                                >
                                    <Grid item>
                                        <ExitToAppIcon />
                                    </Grid>
                                    <Grid item>
                                    <Typography component="h5" style={{textTransform: "capitalize"}}  > 
                                    &nbsp;Logout
                                    </Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>
                    </Grid>:<Grid item>
                    <ListItem button className="linkstyle"  component={Link} to={"/signup"} onClick={toggleDrawer(false)}>
                                <Grid
                                container
                                direction="row"
                                justify="space-evenly"
                                alignItems="center"
                                >
                                    <Grid item>
                                        <AccountCircleIcon />
                                    </Grid>
                                    <Grid item>
                                    <Typography component="h5" style={{textTransform: "capitalize"}}  > 
                                    &nbsp;Signup
                                    </Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>
                    </Grid>}

               </Grid>
               </List>    
               </Drawer>  
              </Hidden>
              <Grid item xl={9} lg={9} md={9} sm={6} xs={6}>
              <Hidden mdDown>
                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={4}
                >
                    <Grid item>
                        <Button color="inherit"  onClick={()=>{
                            if(user){
                                history("/searchresults/a/a/a/a/a/all/relavance")
                            }else{
                                setOpen(true);
                            }
                        }} className="linkstyle">
                          <PeopleIcon/> &nbsp;Search Profiles
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button color="inherit"  component={Link} to={"/about-us"} className="linkstyle">
                        <InfoIcon/>&nbsp;About us
                    </Button>
                    </Grid>
                    <Grid item>
                        <Button color="inherit"  component={Link} to={"/contact-us"} className="linkstyle">
                        <PhoneIcon/>&nbsp;Contact us
                        </Button>
                    </Grid>
                    <Grid item>
                            <Button color={ "transparent"} id="accbuttontop"  className="linkstyle"  aria-controls="simple-menu"
                            aria-haspopup="true"
                             
                            onClick={()=>{
                            setShowSideMyAccMenu(!showSideMyAccMenu);
                            }}
                            title="My Account"
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <AccountCircleIcon />
                                    </Grid>
                                    <Grid item><Typography component="h5" style={{textTransform: "capitalize"}}  > 
                                    &nbsp;My Account&nbsp;
                                    </Typography></Grid>
                                    <Grid item><KeyboardArrowDownIcon  /></Grid>
                                </Grid>
                        
                            
                        </Button>
                        <Collapse in={!showSideMyAccMenu} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding style={{zIndex:"-1",position:"absolute",borderRadius:"3%",backgroundColor:"#f8f9fa",border:`2px solid ${theme.palette.secondary.main}`,paddingTop:"2%",paddingBottom:"1%"}}>
                            {user ? (<MenuItem button component={Link}   style={{ color:"black",borderBottom:"1px solid black" }} to={"/myaccount/profile"} title="My Profile" ><AccountCircleIcon/>&nbsp;&nbsp;My Profile</MenuItem>):
                            (<MenuItem   style={{ color:"black",borderBottom:"1px solid black" }} button title="Login" component={Link} to={"/login"}><ExitToAppIcon/>&nbsp;&nbsp;Login</MenuItem>)}

                            {user ? ( <MenuItem button style={{ color:"black",borderBottom:"1px solid black" }} onClick={()=>{ logout();}} title="Logout" ><ExitToAppIcon/>&nbsp;&nbsp;Logout</MenuItem>):
                            (<MenuItem component={Link} button  to={"/signup"}  style={{ color:"black",borderBottom:"1px solid black" }} title="SignUp"><AccountCircleIcon/>&nbsp;&nbsp;SignUp</MenuItem>)}
                      
                            </List>           
                            </Collapse>       
                  </Grid>
                    {/* {user? <Grid item>
                        <Button color="inherit"   className="linkstyle" title="Logout" onClick={()=>{
                                    logout();
                                }} >
                                 <ExitToAppIcon/>&nbsp;Logout
                                </Button>
                    </Grid>:<Grid item>
                        <Button color="inherit"  component={Link} to={"/login"} className="linkstyle">
                        <ExitToAppIcon/>&nbsp;LOGIN 
                        </Button>
                    </Grid>}
                    {user? <Grid item>
                        <Button color="inherit"  component={Link} to={"/myaccount"} className="linkstyle">
                        <PersonIcon/>&nbsp;My Account 
                        </Button>
                    </Grid>:<Grid item>
                        <Button color="inherit"  component={Link} to={"/signup"} className="linkstyle">
                        <PersonIcon/>&nbsp;SIGNUP 
                        </Button>
                    </Grid>} */}
                </Grid>
              </Hidden>  
               </Grid> 
          </Grid>
        </Container> 
    </AppBar>);

}