import {useState,useEffect,useContext} from 'react';
import {UserContext,SnackbarContext} from './UserContext';
import {Grid, Hidden,useTheme,Card,CardContent,RadioGroup,Radio,FormLabel,FormControlLabel, CardActions,Typography,CardMedia, ButtonBase,Button,makeStyles,Container,Select,MenuItem,Box,InputLabel,FormControl} from '@material-ui/core';
import {BackDropLoad} from './BackdropLoading';
import {Loading } from "./loading";
import { Helmet  } from 'react-helmet-async';
 
import { Routes,Navigate,useNavigate,Link,
    Route,useParams } from 'react-router-dom';


const axios = require('axios').default;

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "& .MuiFormLabel-root":{
        color:"primary"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#ffffff",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });



export const SearchResults=( )=>{
    const theme = useTheme();
      const classes = useStyles();
      let da= useParams();
    let {pf,ms,ca,co,pc,sb,age} = useParams()
    let history = useNavigate ();

    const {user, setUser} = useContext(UserContext);
     
    const {snack, setSnack} = useContext(SnackbarContext);
    const [isloading,setIsloading] = useState(true);

    const [title,setTitle] = useState(); 

    const [wait,setWait] = useState(false);
     


      //lists
       
      const [castes,setCastes] = useState([]);  
      const [countries,setCountries] = useState([]);
      const [maritalstatuses,setMaritalstatuses] = useState([]);
      const [professions,setProfessions] = useState([]);

      const [profiles,setProfiles] = useState([]);

      const [profilefor,setProfilefor] = useState(pf);
      const [maritalstatus,setMartialStatus] = useState(ms);
      const [caste,setCaste] = useState(ca);
      const [country,setCountry] = useState(co);
      const [profCat,setProfCat] = useState(pc); 

      const [ages,setAges] = useState(age);

      const [notfound,setNotfound] = useState(false);

      const [sortby,setSortby] = useState(sb);

      const [err,setErr] = useState(0)

    useEffect(()=>{
      window.scrollTo(0, 0);
        getData();
    },[da])

    const getData=()=>{
         
            const formData = new FormData();
            if(profilefor!=='a'){
                formData.append('gender',profilefor);
            }
            if(maritalstatus!=='a'){
                formData.append('mstatus',maritalstatus);
            }
            if(caste!=='a'){
                formData.append('caste',caste);
            }
            if(country!=='a'){
                formData.append('country',country);
            }
            if(profCat!=='a'){
                formData.append('profcat',profCat);
            }
            formData.append('sort',sortby);
            formData.append('ages',ages);
            axios.post("/web/search_profiles",formData ).then((res)=>{
                if(res.data.status==true){
                    setTitle(res.data.seo_title)
                    setCastes(res.data.castes)
                    setCountries(res.data.countries)
                    setMaritalstatuses(res.data.maritalstatuses)
                    setProfessions(res.data.professions)
                    setProfiles(res.data.data)
                    setNotfound(res.data.notfound)
                    setIsloading(false)
                    setWait(false)
    
                }
            });
        
    }



    return (<div>
      {title && <Helmet>
  <title>{title}</title>
     
 </Helmet>}
 <BackDropLoad wait={wait} />
            {isloading?<Loading/>:<div>
              <div className="container">
                   <br/>
                  <Box boxShadow={3} p={3}  m={4} style={{border:"2px solid #bfaa8d",borderRadius:"5px",backgroundColor:"white" }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={4}
                    >
                        <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" className={classes.root}
                                    size="small"
                                    
                                    fullWidth  >
                                        <InputLabel >
                                        Looking for *
                                        </InputLabel>
                                    <Select
                                         error={err===1 && true}
                                        value={profilefor}
                                        onChange={(e) => {
                                            
                                        setProfilefor(e.target.value);
                                            
                                        }}
                                        style={{color:theme.palette.primary.main}}
                                        
                                        label="Looking for *"
                                        
                                    >
                                      <MenuItem
                                  key="a"
                                  value="a"
                                  color="primary"
                                >
                                 Select Brides or Grooms
                                </MenuItem>
                                  <MenuItem
                                  key="female"
                                  value="female"
                                  color="primary"
                                >
                                  Brides
                                </MenuItem>     
                                <MenuItem
                                  key="male"
                                  value="male"
                                  color="primary"
                                >
                                  Grooms
                                </MenuItem>
                              
                                         
                                    
                                    </Select>
                                </FormControl> 
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" className={classes.root}
                                    size="small"
                                    
                                    fullWidth  >
                                        <InputLabel >
                                        Marital Status
                                        </InputLabel>
                                    <Select
                                         
                                        value={maritalstatus}
                                        onChange={(e) => {
                                            
                                        setMartialStatus(e.target.value);
                                            
                                        }}
                                        style={{color:theme.palette.primary.main}}
                                        
                                        label="Marital Status"
                                        
                                    >
                                      <MenuItem
                                  key="a"
                                  value="a"
                                  color="primary"
                                >
                                  Select Marital Status
                                </MenuItem>
                                       { maritalstatuses &&
                     maritalstatuses.map((ms) => {
                          
                            return (
                                <MenuItem
                                  key={ms.id}
                                  value={ms.name}
                                  color="primary"
                                >
                                  {ms.name}
                                </MenuItem>
                              );})}
                                         
                                    
                                    </Select>
                                </FormControl> 
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                        <FormControl component="fieldset">
                        <FormLabel component="legend">Age: </FormLabel>
                        <RadioGroup  row aria-label="position" name="position"   value={ages} onChange={(e)=>{
                            setAges(e.target.value)
                             
                        }}>
                            <FormControlLabel value="all" control={<Radio color="primary"/>} label="All Ages" style={{ minWidth: 125 }} />
                            <FormControlLabel value="20s" control={<Radio  color="primary"/>} label="21 - 30" style={{ minWidth: 125 }}/>
                            <FormControlLabel value="30s" control={<Radio  color="primary"/>} label="31 - 40" style={{ minWidth: 125 }} />
                            <FormControlLabel value="40s" control={<Radio  color="primary"/>} label="41 - 50" style={{ minWidth: 125 }} />
                            <FormControlLabel value="50s" control={<Radio  color="primary"/>} label="51 and Above" style={{ minWidth: 125 }} />
                        </RadioGroup>
                        </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" className={classes.root}
                                    size="small"
                                    
                                    fullWidth  >
                                        <InputLabel >
                                        Caste 
                                        </InputLabel>
                                    <Select
                                        // native
                                        value={caste}
                                        
                                        onChange={(e) => {
                                            
                                        setCaste(e.target.value);
                                            
                                        }}
                                        style={{color:theme.palette.primary.main}}
                                        
                                        label="Caste"
                                        
                                    >
                                      <MenuItem
                                  key="a"
                                  value="a"
                                  color="primary"
                                >
                                  Select Caste
                                </MenuItem>
                                       { castes &&
                     castes.map((cas) => {
                          
                            return (
                                <MenuItem
                                  key={cas.id}
                                  value={cas.name}
                                  color="primary"
                                >
                                  {cas.name}
                                </MenuItem>
                              );})}
                                         
                                    
                                    </Select>
                                </FormControl> 
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" className={classes.root}
                                    size="small"
                                    
                                    fullWidth  >
                                        <InputLabel >
                                        Current Country 
                                        </InputLabel>
                                    <Select
                                         
                                        value={country}
                                        onChange={(e) => {
                                            
                                        setCountry(e.target.value);
                                            
                                        }}
                                        style={{color:theme.palette.primary.main}}
                                        
                                        label="Current Country "
                                        
                                    >
                                       <MenuItem
                                  key="a"
                                  value="a"
                                  color="primary"
                                >
                                  Select Country
                                </MenuItem>
                                       { countries &&
                     countries.map((country) => {
                          
                            return (
                                <MenuItem
                                  key={country.id}
                                  value={country.name}
                                  color="primary"
                                >
                                  {country.name}
                                </MenuItem>
                              );})}
                                         
                                    
                                    </Select>
                                </FormControl> 
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" className={classes.root}
                                    size="small"
                                    
                                    fullWidth  >
                                        <InputLabel >
                                        Profession Category  
                                        </InputLabel>
                                    <Select
                                         
                                        value={profCat}
                                        onChange={(e) => {
                                            
                                          setProfCat(e.target.value);
                                           
                                            
                                        }}
                                        style={{color:theme.palette.primary.main}}
                                        
                                        label="Profession Category   "
                                        
                                    >
                                      <MenuItem
                                  key="a"
                                  value="a"
                                  color="primary"
                                >
                                  Select Profession Category
                                </MenuItem>
                                       { professions &&
                     professions.map((profession) => {
                          
                            return (
                                <MenuItem
                                  key={profession.id}
                                  value={profession.name}
                                  color="primary"
                                >
                                  {profession.name}
                                </MenuItem>
                              );})}
                                         
                                    
                                    </Select>
                                </FormControl> 
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" className={classes.root}
                                    size="small"
                                    
                                    fullWidth  >
                                        <InputLabel >
                                        Sort by 
                                        </InputLabel>
                                    <Select
                                         
                                        value={sortby}
                                        onChange={(e) => {
                                            
                                        setSortby(e.target.value);
                                            
                                        }}
                                        style={{color:theme.palette.primary.main}}
                                        
                                        label="Sort by"
                                        
                                    >
                                  <MenuItem
                                  key="relavance"
                                  value="relavance"
                                  color="primary"
                                >
                                  Relavant Profiles
                                </MenuItem>     
                                <MenuItem
                                  key="latest"
                                  value="latest"
                                  color="primary"
                                >
                                  Latest Profiles
                                </MenuItem>
                              
                                         
                                    
                                    </Select>
                                </FormControl> 
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} className="text-center">
                                <Button variant="contained" color="primary" style={{width:'70%'}} onClick={()=>{
                                        setErr(0)
                                        if(profilefor==='a'){
                                          setErr(1)
                                          setSnack({
                                            message: "Please select Profile for..",
                                            type: 'error',
                                            open: true,
                                            direction:"center"
                                          })
                                        }else{
                                           setWait(true)
                                          history("/searchresults/"+profilefor+"/"+maritalstatus+"/"+caste+"/"+country+"/"+profCat+"/"+ages+"/"+sortby)
                                        }
                                }}  className="linkstyle"    >
                                   Get Profiles
                                </Button>
                        </Grid>
                </Grid>
                 
                </Box>
                <br/>
                {(notfound===true && profiles.length!==0) && <Typography variant="body1" className="text-center" gutterBottom><b>No Matching Profiles Found.. Below are some Relevant profiles..</b></Typography>}
                {(profiles.length===0) && <Typography variant="body1" className="text-center" gutterBottom><b>No Matching Profiles Found..</b></Typography>}
                <br/>
                <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={4}
            >
                {profiles.map((da)=>{
                            return (<Grid item xs={12} sm={6} md={3}>
 
 <ButtonBase   component={Link} style={{width:"100%"}} to={"/profile/"+da.id}>
                                    <Card className={classes.cardshadow}   style={{ backgroundColor:theme.palette.white.main,padding:"10px",width:"100%"}}>
                                    <CardMedia  
                                        style={{height: 350 }}
                                        image={"/send_file/profilepics/"+da.dpic}
                                        title={da.dpic} 
                                        />  
                                           <CardContent>  
                                         
                                         
                                            <Typography variant="body1" gutterBottom><b>Name:</b> {da.name} {da.surname}</Typography>
                                            <Typography variant="body1" gutterBottom><b>Gender:</b> {da.gender}</Typography>
                                            <Typography variant="body1" gutterBottom><b>Age:</b> {da.age}</Typography>
                                            <Typography variant="body1" gutterBottom><b>Marital Status:</b> {da.maritalstatus}</Typography>
                                            <Typography variant="body1" gutterBottom><b>Caste:</b> {da.caste}</Typography>
                                            <Typography variant="body1" gutterBottom><b>Country:</b> {da.country}</Typography>
                                            <Typography variant="body1" gutterBottom><b>Profession Category:</b> {da.profCat}</Typography>
                                            </CardContent>
                                             {/* <CardActions>
                                              <Button variant="contained" component={Link} className="linkstyle"  color="primary" to={"/profile/"+da.id} fullWidth>
                                                VIEW PROFILE</Button>
                                                
                                            </CardActions>   */}
                                    </Card>
                                    </ButtonBase>
                            </Grid>);
                })
            }   
            </Grid>
            <br/><br/>
              </div>
          </div>}
    </div>)
}