
import {useState,useEffect,useContext} from 'react';
import {Grid,Container,Box,Button,Card,Fab, CardActions,makeStyles,CardContent,CardMedia,Dialog,DialogActions,Avatar,DialogContent,FormControlLabel,FormLabel, DialogContentText,DialogTitle,Snackbar,TextField,useTheme, Typography} from '@material-ui/core';
import {UserContext,SnackbarContext} from './UserContext';
import {BackDropLoad} from './BackdropLoading';
import {Loading } from "./loading";
import AddIcon from '@material-ui/icons/Add';
import {useNavigate,useParams,Link } from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
const headKey="asda()*^%~`)sdawqe23123>;p988u12hmmasd";
const axios = require('axios').default;


const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "& .MuiFormLabel-root":{
        color:"primary"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#ffffff",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });

export const UserProfiles=()=>{

    let {id} = useParams();
    let history = useNavigate ();
    const theme = useTheme();
    const classes = useStyles();
    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);

    const [open,setOpen] = useState(false);
    const [deleteId,setDeleteId] = useState("");
    const [deleteName,setDeleteName] = useState("");


    const [data,setData] = useState();
     

    const [isloading,setIsloading] = useState(true);


    useEffect(()=>{
      window.scrollTo(0, 0);
        getData();
    },[])

    const getData=()=>{
         
        axios.post("/web/get_all_user_profiles" ).then((res)=>{
            if(res.data.status===1){
                 
                 setData(res.data.data)
                  
                 
               setIsloading(false)
            }else if(res.data.status===3){
                setSnack({
                    message: res.data.msg,
                    type: 'warning',
                    open: true,
                    direction:"left"
                });
                  setUser(null)
                  history("/")
            }
            
        });
    }


    const deleteProfile=()=>{
        setOpen(false)
       setIsloading(true)
       const formData=new FormData();
       formData.append('id',deleteId);
       axios.post("/web/delete_profile",formData ).then((res)=>{
            
           if(res.data.status===1){
               setSnack({
                   message: res.data.msg,
                   type: 'success',
                   open: true,
                   direction:"center"
               });
                  getData()
           }else if(res.data.status===3){
            setSnack({
                message: res.data.msg,
                type: 'warning',
                open: true,
                direction:"left"
            });
              setUser(null)
              history("/")
           } 
       });
    }



    return (<div className="container">
      <Helmet>
         <title>My Profiles | Kapu Kalyanalu</title>
            
         </Helmet>
         <Dialog  onClose={()=>{
                   setOpen(false)
               }} aria-labelledby="simple-dialog-title" open={open}>
                    <DialogTitle>! Please Confirm</DialogTitle>
                   <DialogContent>
                       Are you sure to delete <b>{deleteName}'s</b> Profile?
                   </DialogContent>
                   <DialogActions>
                       <Button  variant="contained" color="primary" onClick={deleteProfile}>
                           YES
                       </Button>
                       <Button onClick={()=>setOpen(false)} variant="contained" color="primary">
                           NO
                       </Button>
                   </DialogActions>
               </Dialog>
         
                 
                 
       {isloading?<Loading/>:( <Box  boxShadow={3} p={3}   style={{border:"2px solid #bfaa8d",borderRadius:"5px" ,backgroundColor:"white",marginBottom:"10%",backgroundImage:"url('/static/backgrounds/profilebg.jpg')"}}>
         
        <br/>
       {data && <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={4}
            >
                <Grid item xs={12}>
            <Typography variant="h4" className="text-center" gutterBottom style={{color:theme.palette.secondary.main}}>
                 My Profiles
            </Typography>
            <hr style={{color:theme.palette.secondary.main}}/>
            <Grid item xs={12} className="text-center">
            <Typography variant="body1">Every user can Add <b>2 Profiles</b>.</Typography>
            </Grid>
            </Grid>
                {data.map((da)=>{
                            return (<Grid item xs={12} sm={6} md={4}>
                                    <Card className={classes.cardshadow}   style={{ backgroundColor:theme.palette.white.main,padding:"10px"}}>
                                    <CardMedia  
                                        style={{height: 350 }}
                                        image={da.dpic}
                                        title={da.dpic} 
                                        />  
                                           <CardContent>  
                                         
                                         
                                            <Typography variant="body1" gutterBottom><b>Name:</b> {da.name}</Typography>
                                            <Typography variant="body1" gutterBottom><b>Gender:</b> {da.gender}</Typography>
                                            <Typography variant="body1" gutterBottom><b>Profession Category:</b> {da.profCat}</Typography>
                                            <Typography variant="body1" gutterBottom><b>Designation:</b> {da.designation}</Typography>
                                            <Typography variant="body1" gutterBottom><b>Status:</b> {da.status===1&&(<span style={{color:"green"}}>Active</span>)}{da.status===0&&(<span style={{color:"black"}}>Waiting for Approval</span>)}
                                            {da.status===2&&(<span style={{color:"red"}}>Rejected</span>)}</Typography>
                                            <Typography variant="body1" gutterBottom><b>Added/Updated Data:</b> {da.added_date}</Typography>
                                            </CardContent>
                                            <CardActions>
                                            <Button variant="contained" component={Link} className="linkstyle"  color="primary" to={"/myaccount/view_profile/"+da.id} fullWidth>
                                                VIEW</Button>
                                                <Button variant="contained"  className="linkstyle"   color="primary"  fullWidth onClick={()=>{
                                    setDeleteId(da.id);
                                    setDeleteName(da.name);
                                    setOpen(true)
                                }}>
                                   DELETE</Button>
                                            </CardActions>
                                    </Card>
                            </Grid>);
                })
            }       
              {data.length===0 &&  <Grid item xs={12}>
            <Typography variant="body1" className="text-center" gutterBottom style={{color:theme.palette.secondary.main}}>
                 No Profile Added So far....
            </Typography>
             
            </Grid>}
            {data.length<2 &&  <Grid item xs={12} className="text-right">
            <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={6}
                    >
                    <Grid item  >
                        <Fab variant="extended" color="primary" aria-label="add" className="linkstyle" component={Link} to={"/myaccount/add_profile"}  >
                            <AddIcon   />
                            Add Profile
                        </Fab>
            </Grid></Grid>
             
            </Grid>}
        </Grid>}  
       </Box>)}
        
       
       </div>);


}