
import {Grid,Container,ButtonBase} from '@material-ui/core';
import {Link} from 'react-router-dom';

export const Footer =()=>{
    return (<div className="main-footer" >
        <div className="container">
         <Grid 
         container
         direction="row"
         justify="center"
         alignItems="center"
         spacing={4}
         >
             <Grid item xs={12}  >
                 
             </Grid>
             {/* <Grid item xs={12} sm={6} md={3}>
                 <p className="text-center">
                     <ButtonBase component={Link}  className="footerlinks"   color="primary" to={"/about-us"}>
                 ABOUT US
                 </ButtonBase>
                 </p>
             </Grid>
             <Grid item xs={12} sm={6} md={3}>
                <p className="text-center">
                <ButtonBase component={Link}    className="footerlinks"   color="primary" to={"/terms"}>
                    TERMS & CONDTIONS
                    </ButtonBase>
                 </p>
             </Grid>
             <Grid item xs={12} sm={6} md={3}>
                <p className="text-center">
                <ButtonBase component={Link} className="footerlinks"   color="primary" to={"/privacy"}>
                    PRIVACY POLICY
                  </ButtonBase>  
                 </p>
             </Grid>
             <Grid item xs={12} sm={6} md={3}>
                <p className="text-center">
                <ButtonBase component={Link}   className="footerlinks"    color="primary" to={"/contact-us"}>
                     CONTACT US
                    </ButtonBase>
                 </p>
             </Grid> */}
             <Grid item xs={12} style={{paddingTop:"0px",paddingBottom:"0px"}} >
                 <hr />
             </Grid>
             <Grid item xs={12} sm={12} md={6} >
                <p  >
                Powered by <a href="https://www.arawinz.com/" className='footerLink' style={{color:"black",fontWeight:"bold"}} target="_blank">Arawinz soft solutions pvt ltd. © 2018 - 2022</a>
                </p>
             </Grid>
             <Grid item xs={12} sm={6} md={3} >
             <p  >
                <ButtonBase component={Link}    className="footerlinks"   color="primary" to={"/terms"}>
                    TERMS & CONDTIONS
                    </ButtonBase>
                 </p>
             </Grid>
             <Grid item xs={12} sm={6} md={3} >
             <p  >
                <ButtonBase component={Link} className="footerlinks"   color="primary" to={"/privacy"}>
                    PRIVACY POLICY
                  </ButtonBase>  
                 </p>
             </Grid>
              
         </Grid>
         </div>
    </div>);
}