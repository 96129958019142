import {useState,useEffect,useContext} from 'react';
import {Grid,Container,Box,Button,Switch, makeStyles,FormControl,InputLabel,Radio,RadioGroup,Select,MenuItem,FormControlLabel,FormLabel, DialogContentText,DialogTitle,Snackbar,TextField,useTheme} from '@material-ui/core';
import {UserContext,SnackbarContext} from './UserContext';
import {BackDropLoad} from './BackdropLoading';
import {Loading } from "./loading";
import {useNavigate,useParams } from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
const headKey="asda()*^%~`)sdawqe23123>;p988u12hmmasd";
const axios = require('axios').default;


const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "& .MuiFormLabel-root":{
        color:"primary"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#ffffff",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });

export const UpdatePassword = ()=>{
    let {id} = useParams();
    let history = useNavigate ();
    const theme = useTheme();
      const classes = useStyles();
    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);

    const [isloading,setIsloading] = useState(false);

    const [curpass,setCurpass] = useState("");
    const [pass,setPass] = useState("");
    const [repass,setRePass] = useState("");
    const [status,setStatus] = useState(1);

    const [radioValue,setRadioValue] = useState("type");

    const [err,setErr] = useState(0);
    const [wait,setWait] = useState(false);

      useEffect(()=>{
        window.scrollTo(0, 0);
      },[])

      

     const UpdateUserPassword=(e)=>{
        setErr(0);
        e.preventDefault();
        if(curpass===''){
            setErr(3);
            setSnack({
                message: 'Enter Current your Password..',
                type: 'error',
                open: true,
                direction:"center"
            });
        }else if(pass===''){
            setErr(4);
            setSnack({
                message: 'Enter your Password..',
                type: 'error',
                open: true,
                direction:"center"
            });
        }else if(pass!==repass){
            setErr(5);
            setSnack({
                message: 'Re-Entered Password not matching',
                type: 'error',
                open: true,
                direction:"center"
            });
        }else{
            setWait(true);
            const formData=new FormData();
            formData.append('id',id);
            formData.append('curpass',curpass);
            formData.append('pass',pass);
            axios.post("/web/update_user_password",formData ).then((res)=>{
                 
                if(res.data.status===1){
                    setSnack({
                        message: res.data.msg,
                        type: 'success',
                        open: true,
                        direction:"center"
                    });
                  setWait(false)
                   
                   
                }else if(res.data.status===2){
                  setWait(false)
                  setSnack({
                    message: res.data.msg,
                    type: 'error',
                    open: true,
                    direction:"center"
                });
                }else if(res.data.status===3){
                    setWait(false)
                    setSnack({
                      message: res.data.msg,
                      type: 'warning',
                      open: true,
                      direction:"left"
                  });
                    setUser(null)
                    history("/")
                  }
              });
        }
     }
     
     
     

     

    return (<div>
         <Helmet>
         <title>Update Password | Kapu Kalyanalu</title>
            
         </Helmet>
        <Container  maxWidth="sm"
             >
                <BackDropLoad wait={wait}/>
                 
       {isloading?<Loading/>:( <Box  boxShadow={3} p={3}   style={{border:"2px solid #bfaa8d",borderRadius:"5px",backgroundColor:"white"}}>
        
        <form onSubmit={UpdateUserPassword}>
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={4}
            
        >
            <Grid item xs={12}> 
                <h3  className='text-center'> Update Password </h3>
                <hr/>
            </Grid>
            
            <Grid  item xs={12} > 
                <TextField id="outlined-basic" label="Current Password *" variant="outlined"  className={classes.root}
                error={err===3 && true}
                type="password"
                value={curpass}
                onChange={(e)=>{
                    setCurpass(e.target.value);
                }}
                size='small'
                fullWidth  />
            </Grid>
            <Grid  item xs={12} > 
                <TextField id="outlined-basic" label="New Password *" variant="outlined"  className={classes.root}
                error={err===4 && true}
                type="password"
                value={pass}
                onChange={(e)=>{
                    setPass(e.target.value);
                }}
                size='small'
                fullWidth  />
            </Grid>
            <Grid  item xs={12} > 
                <TextField id="outlined-basic" label="Re-Enter New Password *" variant="outlined"  className={classes.root}
                error={err===5 && true}
                type="password"
                value={repass}
                onChange={(e)=>{
                    setRePass(e.target.value);
                }}
                size='small'
                 
                  fullWidth  />
            </Grid>
            <Grid  item xs={12}  className="text-center">
            <Button variant="contained" color="primary" style={{width:'60%'}}  className="linkstyle"  type="submit" disabled={wait} >
               {wait?"Please wait..":"Update Password"} 
            </Button>
            </Grid>
         </Grid>
         </form>  
          
        </Box>)}
        </Container>
         
    </div>);
}