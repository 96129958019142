import {useState,useEffect,useContext} from 'react';
import {Checkbox,Link, Grid,Dialog,DialogActions,DialogContent, Container,Box,Button, makeStyles,FormControl,InputLabel,Radio,RadioGroup,Select,MenuItem,FormControlLabel,FormLabel, DialogContentText,DialogTitle,Snackbar,TextField,useTheme} from '@material-ui/core';
import {UserContext,SnackbarContext} from './UserContext';
import {BackDropLoad} from './BackdropLoading';
import {Loading } from "./loading";
import {useNavigate } from 'react-router-dom';
import { Helmet  } from 'react-helmet-async';
const headKey="asda()*^%~`)sdawqe23123>;p988u12hmmasd";
const axios = require('axios').default;


const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "& .MuiFormLabel-root":{
        color:"primary"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#ffffff",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });

export const Signup = ()=>{
    let history = useNavigate ();
    const theme = useTheme();
      const classes = useStyles();


      const [title,setTitle] = useState(); 

    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);

    const [isloading,setIsloading] = useState(false);

    const [open, setOpen] = useState(false);

    const [gender,setGender] = useState("Mr. ");
    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [mob,setMob] = useState("");
    const [pass,setPass] = useState("");
    const [repass,setRePass] = useState("");
    const [terms,setTerms]=useState(true);



    const [otp,setOtp] = useState("");

    const [radioValue,setRadioValue] = useState("type");

    const [err,setErr] = useState(0);
    const [wait,setWait] = useState(false);

      
    useEffect(()=>{
      window.scrollTo(0, 0);
      getMeta()
    },[])


    const getMeta=()=>{
      const formData= new FormData();
      formData.append('page','signup');
      
       
      axios.post("/web/get_single_metadata",formData ).then(  (res) => {
             
              if(res.data.status ){
                setTitle(res.data.data.seo_title)
              }
            });     
    }
     

    const addUser=(e)=>{
        setErr(0);
        e.preventDefault();
        if(name===''){
            setErr(1);
            setSnack({
                message: 'Enter your Name..',
                type: 'error',
                open: true,
                direction:"center"
            });
        }else if(!email.includes('@')){
            setErr(2);
            setSnack({
                message: 'Enter a Valid Email-ID..',
                type: 'error',
                open: true,
                direction:"center"
            });
        }else if(mob.length!=10){
            setErr(3);
            setSnack({
                message: 'Enter a valid 10 Digit Mobile Number...',
                type: 'error',
                open: true,
                direction:"center"
            });
        }else if(pass.length<6){
            setErr(4);
            setSnack({
                message: 'Your Password should have atleast 6 Characters..',
                type: 'error',
                open: true,
                direction:"center"
            });
        }else if(pass!==repass){
            setErr(5);
            setSnack({
                message: 'Re-Entered Password not matching',
                type: 'error',
                open: true,
                direction:"center"
            });
        }else if(terms===false){
            setErr(6);
            setSnack({
              message: 'Please Read and Agree to our Terms and Conditions..',
              type: 'error',
              open: true,
              direction:"center"
            });
        }else{
            setWait(true);
            const formData=new FormData();
            formData.append('gender',gender);
            formData.append('name',name);
            formData.append('email',email);
            formData.append('mobile',mob);
            formData.append('pass',pass);
            axios.post("/web/signup",formData ).then((res)=>{
                 
                if(res.data.status){
                    setSnack({
                        message: res.data.msg,
                        type: 'success',
                        open: true,
                        direction:"center"
                    });
                  setWait(false)
                setOpen(true);
                   
                }else{
                  setWait(false)
                  setSnack({
                    message: res.data.msg,
                    type: 'error',
                    open: true,
                    direction:"center"
                });
                }
              });
        }
    }

    const submitOtp=(e)=>{
        setErr(0);
        e.preventDefault();
        if(otp===''){
            setErr(7);
            setSnack({
                message: 'Enter your OTP..',
                type: 'error',
                open: true,
                direction:"center"
            });
        }else{
            setOpen(false)
            setWait(true);
            const formData=new FormData();
            formData.append('gender',gender);
            formData.append('name',name);
            formData.append('email',email);
            formData.append('mobile',mob);
            formData.append('pass',pass);
            formData.append('otp',otp);
            axios.post("/web/signup",formData,{
                headers:{headKey:headKey}
              }).then((res)=>{
                 
                if(res.data.status){
                    setSnack({
                        message: res.data.msg,
                        type: 'success',
                        open: true,
                        direction:"left"
                    });
                     
                  setUser(res.data.data)
                  history('/myaccount/profile')
                  setWait(false)
                   
                  
                }else{
                  setWait(false)
                  setOpen(true)
                  setSnack({
                    message: res.data.msg,
                    type: 'error',
                    open: true,
                    direction:"center"
                });
                }
              });
        }
    }

    return (<div>
         {title && <Helmet>
  <title>{title}</title>
     
 </Helmet>}
        <Container  maxWidth="sm"
             >
                <BackDropLoad wait={wait}/>
                <Dialog
            open={open}
            onClose={()=>{

            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >

            <DialogTitle id="alert-dialog-title">{"Enter OTP"}</DialogTitle>
            <form onSubmit={submitOtp}> 
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                                    Please Enter One Time Password
                    </DialogContentText>
                    <TextField id="outlined-basic" label="OTP" variant="filled" 
                        error={err===7 && true}
                        value={otp}
                        onChange={(e)=>{
                            setOtp(e.target.value);
                        }}
                        size='small'
                         
                          fullWidth  />
                          
                         
               </DialogContent>

                    <DialogActions className="text-center">
                        
                        
                        
                        
                        
                        <Button  type="submit" variant="contained" className="linkstyle"  color="primary" style={{width:'60%'}} disabled={wait} >
                            SUBMIT
                        </Button>
                         
                        
                        
                     </DialogActions>
                     </form>
         </Dialog>
       {isloading?<Loading/>:( <Box  boxShadow={3} p={3} m={4} style={{border:"2px solid #333333",borderRadius:"5px" ,backgroundColor:"white"}}>
        <form onSubmit={addUser}>
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={4}
            
        >
            <Grid item xs={12}> 
                <h3  className='text-center'> SIGNUP</h3>
                <hr/>
            </Grid>
            <Grid item xs={4}>
            <FormControl variant="outlined" className={classes.root}
                  size="small"
                   
                  fullWidth  >
                    
                   <Select
                    // native
                     value={gender}
                     onChange={(e) => {
                        
                       setGender(e.target.value);
                        
                     }}
                     style={{color:theme.palette.primary.main}}
                      
                     label="Choose Perminant or Temperory"
                      
                   >
                    <MenuItem key="Mr. "  value="Mr. "  >Mr. </MenuItem>
                    <MenuItem key="Mrs. "  value="Mrs. "  >Mrs. </MenuItem>
                    <MenuItem key="Ms. "  value="Ms. "  >Ms. </MenuItem>
                  
                   </Select>
            </FormControl>       
            </Grid>
            <Grid  item xs={8} > 
                <TextField id="outlined-basic" label="Name *" variant="outlined"  className={classes.root}
                error={err===1 && true}
                value={name}
                 
                onChange={(e)=>{
                    setName(e.target.value);
                     
                }}
                size='small'
                  fullWidth  
                   
                  />
            </Grid>
            
            <Grid  item xs={12} > 
                <TextField id="outlined-basic" label="Email-ID *" variant="outlined"  className={classes.root}
                error={err===2 && true}
                
                value={email}
                onChange={(e)=>{
                    setEmail(e.target.value);
                }}
                size='small'
                 
                  fullWidth  />
            </Grid>
            <Grid  item xs={12} > 
                <TextField id="outlined-basic" label="Mobile Number *" variant="outlined"  className={classes.root}
                error={err===3 && true}
                 type="number"
                value={mob}
                onChange={(e)=>{
                    setMob(e.target.value);
                    if(radioValue=='mob'){
                        setPass(e.target.value)
                        setRePass(e.target.value)
                    }
                }}
                size='small'
                 
                  fullWidth  />
            </Grid>
             
            <Grid  item xs={12} > 
                <TextField id="outlined-basic" label="Password *" variant="outlined"  className={classes.root}
                error={err===4 && true}
                type="password"
                value={pass}
                onChange={(e)=>{
                    setPass(e.target.value);
                }}
                size='small'
                fullWidth  />
            </Grid>
            <Grid  item xs={12} > 
                <TextField id="outlined-basic" label="Re-Enter Password *" variant="outlined"  className={classes.root}
                error={err===5 && true}
                type="password"
                value={repass}
                onChange={(e)=>{
                    setRePass(e.target.value);
                }}
                size='small'
                 
                  fullWidth  />
            </Grid>
            <Grid item xs={12}  >
            <Checkbox
                    checked={terms}
                     
                    onChange={()=>{
                        setTerms(!terms);
                    }}
                    name="checkedB"
                    color="primary"
                /> I here by read and agree to the <a href="/terms"  target="_blank"  className='footerLink' style={{color:"black"}}  >Terms and Conditions</a>.{err===6 && (<span style={{color:"red"}}><br/>Read and agree to our Terms and Conditions.</span>)}
            </Grid>
            <Grid  item xs={12} className="text-center">
            <Button variant="contained" color="primary" style={{width:'60%'}} className="linkstyle"   type="submit" disabled={wait} >
               {wait?"Please wait..":"REGISTER NOW"} 
            </Button>
            </Grid>
        </Grid>
        </form>
        </Box>)}
        </Container>
         
    </div>);
}