import {useState,useEffect,useContext} from 'react';
import {Grid,Container,Box,Button,Switch, makeStyles,FormControl,InputLabel,Radio,RadioGroup,Select,MenuItem,FormControlLabel,FormLabel, DialogContentText,DialogTitle,Snackbar,TextField,useTheme} from '@material-ui/core';
import {UserContext,SnackbarContext} from './UserContext';
import {BackDropLoad} from './BackdropLoading';
import {Loading } from "./loading";
import {useNavigate,useParams } from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
const headKey="asda()*^%~`)sdawqe23123>;p988u12hmmasd";
const axios = require('axios').default;


const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "& .MuiFormLabel-root":{
        color:"primary"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#ffffff",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });

export const EditUser = ()=>{
     
    let history = useNavigate ();
    const theme = useTheme();
      const classes = useStyles();
    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);

    const [isloading,setIsloading] = useState(true);

    const [gender,setGender] = useState("Mr. ");
    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [mob,setMob] = useState("");
    const [pass,setPass] = useState("");
    const [repass,setRePass] = useState("");
    const [status,setStatus] = useState(1);

    const [radioValue,setRadioValue] = useState("type");

    const [err,setErr] = useState(0);
    const [wait,setWait] = useState(false);

      useEffect(()=>{
        window.scrollTo(0, 0);
        getData();
      },[])

     const getData=()=>{
         
        axios.post("/web/update_user" ).then((res)=>{
            if(res.data.status===1){
                 
                setGender(res.data.data.user_gender)
                setName(res.data.data.user_name)
                setEmail(res.data.data.user_email)
                setMob(res.data.data.user_mob)
                 
                setIsloading(false)
            }else if(res.data.status===3){
                setSnack({
                    message: res.data.msg,
                    type: 'warning',
                    open: true,
                    direction:"center"
                });
                setUser(null)
                history("/")
            }
            
        });
     }

      
     
     
    const updateUserDetails=(e)=>{
        setErr(0);
        e.preventDefault();
        if(name===''){
            setErr(1);
            setSnack({
                message: 'Enter your Name..',
                type: 'error',
                open: true,
                direction:"center"
            });
        }else if(!email.includes('@')){
            setErr(2);
            setSnack({
                message: 'Enter a Valid Email-ID..',
                type: 'error',
                open: true,
                direction:"center"
            });
        } else{
            setWait(true);
            const formData=new FormData();
             
            formData.append('gender',gender);
            formData.append('name',name);
            formData.append('email',email);
             
            axios.post("/web/update_user",formData ).then((res)=>{
                 
                if(res.data.status===1){
                    setSnack({
                        message: res.data.msg,
                        type: 'success',
                        open: true,
                        direction:"center"
                    });
                  setWait(false)
                   
                   
                }else if(res.data.status===2){
                  setWait(false)
                  setSnack({
                    message: res.data.msg,
                    type: 'error',
                    open: true,
                    direction:"center"
                    });
                }else if(res.data.status===3){
                    setSnack({
                        message: res.data.msg,
                        type: 'warning',
                        open: true,
                        direction:"left"
                    });
                    setUser(null)
                    history("/")
                }
              });
        }
    }

     

    return (<div>
         <Helmet>
         <title>Update Details | Kapu Kalyanalu</title>
            
         </Helmet>
        <Container  maxWidth="sm"
             >
                <BackDropLoad wait={wait}/>
                 
       {isloading?<Loading/>:( <Box  boxShadow={3} p={3}  style={{border:"2px solid #bfaa8d",borderRadius:"5px",backgroundColor:"white"}}>
        
        <form onSubmit={updateUserDetails}>
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={4}
            
        >
            <Grid item xs={12}> 
                <h3 className='text-center'> View / Update Details </h3>
                <hr/>
            </Grid>
            <Grid  item xs={12} > 
                <p><b>Registered Mobile Number: </b>{mob}</p>
            </Grid>
            <Grid item xs={4}>
            <FormControl variant="outlined" className={classes.root}
                  size="small"
                   
                  fullWidth  >
                    
                   <Select
                    // native
                     value={gender}
                     onChange={(e) => {
                        
                       setGender(e.target.value);
                        
                     }}
                     style={{color:theme.palette.primary.main}}
                      
                     label="Choose Perminant or Temperory"
                      
                   >
                    <MenuItem key="Mr. "  value="Mr. "  >Mr. </MenuItem>
                    <MenuItem key="Mrs. "  value="Mrs. "  >Mrs. </MenuItem>
                    <MenuItem key="Ms. "  value="Ms. "  >Ms. </MenuItem>
                  
                   </Select>
            </FormControl>       
            </Grid>
            <Grid  item xs={8} > 
                <TextField id="outlined-basic" label="Name *" variant="outlined"  className={classes.root}
                error={err===1 && true}
                value={name}
                 
                onChange={(e)=>{
                    setName(e.target.value);
                     
                }}
                size='small'
                  fullWidth  
                   
                  />
            </Grid>
            
            <Grid  item xs={12} > 
                <TextField id="outlined-basic" label="Email-ID *" variant="outlined"  className={classes.root}
                error={err===2 && true}
                
                value={email}
                onChange={(e)=>{
                    setEmail(e.target.value);
                }}
                size='small'
                 
                  fullWidth  />
            </Grid>
             
            
            
            <Grid  item xs={12} className="text-center">
            <Button variant="contained" color="primary" style={{width:'60%'}}  className="linkstyle"  type="submit" disabled={wait} >
               {wait?"Please wait..":"Update Details"} 
            </Button>
            </Grid>
        </Grid>
        </form>
         
        </Box>)}
        </Container>
         
    </div>);
}