import {useState,useEffect,useContext} from 'react';
import {Grid,Container,Box,Button,Switch, makeStyles,Avatar,InputLabel,Radio,RadioGroup,Select,MenuItem,FormControlLabel,FormLabel, DialogContentText,DialogTitle,Snackbar,TextField,useTheme,Tabs,Tab, Typography,
Dialog,DialogActions,DialogContent} from '@material-ui/core';
import {UserContext,SnackbarContext} from './UserContext';
import {BackDropLoad} from './BackdropLoading';
import {Loading } from "./loading";
import PropTypes from 'prop-types';
import {useNavigate,useParams,Link } from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
const headKey="asda()*^%~`)sdawqe23123>;p988u12hmmasd";
const axios = require('axios').default;


const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary"
      },
      "& .MuiFormLabel-root":{
        color:"primary"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#ffffff",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export const MyRequestedProfiles = ()=>{
    let {id} = useParams();
    let history = useNavigate ();
    const theme = useTheme();
      const classes = useStyles();
    const {user, setUser} = useContext(UserContext);
    const [data,setData] = useState([]);
    const [reqsbyu,setReqsbyu] = useState([]);
    const [reqstou,setReqstou] = useState([]);
    const {snack, setSnack} = useContext(SnackbarContext);

    const [isloading,setIsloading] = useState(true);
    const [value, setValue] =  useState(0);
     
    const [openDeleteDailog,setOpenDeleteDailog] = useState(false);
    const [openUpdateDailog,setOpenUpdateDailog] = useState(false);

    const [deleteid,setDeleteid] = useState();
    const [deletename,setDeletename] = useState("");

    const [updateid,setUpdateid] = useState();
    const [updatename,setupdatename] = useState("");

    const [err,setErr] = useState(0);
    const [wait,setWait] = useState(false);

      useEffect(()=>{
        window.scrollTo(0, 0);
         getData();
      },[])


      const handleChange = (event, newValue) => {
        setValue(newValue);
      };

      const getData=()=>{
        axios.post("/web/get_all_request_profiles" ).then((res)=>{
                 
            if(res.data.status===1){
                 
               setReqsbyu(res.data.reqbyu);
               setReqstou(res.data.reqtou) 
                setIsloading(false);
            }else if(res.data.status===3){
                setSnack({
                  message: res.data.msg,
                  type: 'warning',
                  open: true,
                  direction:"left"
                });
                setUser(null)
                history("/")
            }
          });
      }
      



      const deleteRequest=()=>{
        setOpenDeleteDailog(false)
          setWait(true)
        const formData=new FormData();
        formData.append('id',deleteid);
        axios.post("/web/delete_request",formData ).then((res)=>{
                 
            if(res.data.status===1){
                setReqsbyu(res.data.reqbyu)
                setSnack({
                    message: res.data.msg,
                    type: 'success',
                    open: true,
                    direction:"center"
                  });
                setWait(false)
                
            }else if(res.data.status===3){
                setSnack({
                  message: res.data.msg,
                  type: 'warning',
                  open: true,
                  direction:"left"
                });
                setUser(null)
                history("/")
            } 
        });

      }
      

      const updateRequest=()=>{
        setOpenUpdateDailog(false)
          setWait(true)
        const formData=new FormData();
        formData.append('id',updateid);
        axios.post("/web/update_request",formData ).then((res)=>{
                 
            if(res.data.status===1){
                setReqstou(res.data.reqtou)
                setSnack({
                    message: res.data.msg,
                    type: 'success',
                    open: true,
                    direction:"center"
                  });
                setWait(false)
                
            }else if(res.data.status===3){
                setSnack({
                  message: res.data.msg,
                  type: 'warning',
                  open: true,
                  direction:"left"
                });
                setUser(null)
                history("/")
            } 
        });

      }
     
     
     

     

    return (<div>
         
         <Helmet>
         <title>Profile Request History | Kapu Kalyanalu</title>
            
         </Helmet>
                <BackDropLoad wait={wait}/>
                 
       {isloading?<Loading/>:( <Box  boxShadow={3} p={3}   style={{border:"2px solid #bfaa8d",borderRadius:"5px",backgroundColor:"white",marginBottom:"10%"}}>
       <Dialog  onClose={()=>{
                   setOpenUpdateDailog(false)
               }} aria-labelledby="simple-dialog-title" open={openUpdateDailog}>
                    <DialogTitle>! Please Confirm</DialogTitle>
                   <DialogContent>
                       {updatename}
                   </DialogContent>
                   <DialogActions>
                       <Button  variant="contained" color="primary" onClick={updateRequest}>
                           YES
                       </Button>
                       <Button onClick={()=>setOpenUpdateDailog(false)} variant="contained" color="primary">
                           NO
                       </Button>
                   </DialogActions>
               </Dialog>
       <Dialog  onClose={()=>{
                   setOpenDeleteDailog(false)
               }} aria-labelledby="simple-dialog-title" open={openDeleteDailog}>
                    <DialogTitle>! Please Confirm</DialogTitle>
                   <DialogContent>
                       {deletename}
                   </DialogContent>
                   <DialogActions>
                       <Button  variant="contained" color="primary" onClick={deleteRequest}>
                           YES
                       </Button>
                       <Button onClick={()=>setOpenDeleteDailog(false)} variant="contained" color="primary">
                           NO
                       </Button>
                   </DialogActions>
               </Dialog>
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={4}
            
        >
            <Grid item xs={12}> 
                <h3  className='text-center'>  Profiles Request History</h3>
                <hr/>
            </Grid>
            <Grid item xs={12} className="text-center">
            <Typography variant="body1">Below is the Request History of your Profiles...</Typography>
            </Grid>
            <Grid item xs={12}> 
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" indicatorColor="secondary" variant="scrollable"
                scrollButtons="auto"  style={{backgroundColor:theme.palette.primary.main,color:theme.palette.secondary.main}}>
                <Tab label="Requests to your Profiles" {...a11yProps(0)} />
                <Tab label="Profiles Requested by You" {...a11yProps(1)} />
                 
                
                
              </Tabs>
              <div
                role="tabpanel"
                hidden={value !== 0}
                className="text-center"
                >
                    <br/>
                    <table id="table_id" className="table table-striped">
                <thead>
                        <tr>
                        <th className="text-center">No</th>
                        <th className="text-center">For Profile</th>
                        <th className="text-center">Requested Profile</th>    
                        <th className="text-center">Requested Status</th>    
                        <th className="text-center">Requested Date</th>
                            
                        

                        </tr>
                    </thead>
                    <tbody>
                     
                     
                        {reqstou.length===0 &&<tr><td colSpan="5"><p className="text-center">You have not got any <b>Interested Request</b> to any of your Profiles....</p></td></tr>}
                        {reqstou.map((da,i)=>{
                            return (<tr>
                                <td>{reqstou.length-i}</td>
                                <td className="text-center">
                                    <img src={da.pdpic} className="img-thumbnail"  style={{height:"200px" }}/>
                                     
                                    <br/>
                                    Name: {da.pname} {da.surname}
                                    <br/>
                                    Gender: {da.pgender}
                                    <br/>
                                    Status: {da.pstatus===1?<span style={{color:"green"}}>Active</span>:<span style={{color:"red"}}>Waiting for Approval</span>}
                                </td>
                                <td className="text-center">
                                    <img src={da.rdpic} className="img-thumbnail"  style={{height:"200px" }}/>
                                     
                                    <br/>
                                    Name: {da.rname} {da.rsurname}
                                    <br/>
                                    Gender: {da.rgender}
                                    <br/>
                                    <span>
                                      Contact no: {da.rmob}
                                      <br/>
                                      Email-ID: {da.remail} 
                                      <br/> 
                                    </span> 
                                    {da.rstatus===1 &&<Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/profile/"+da.rid} className="linkstyle"  style={{width:"60%"}}>View Profile</Button>}
                                </td>
                                <td className="text-center">
                                     {da.reqstatus===0?<span>Requested and Waiting for Approval...</span>:<span>Request Approved</span>}<br/><br/>  
                                    {da.reqstatus===0 && <Button variant="contained" fullWidth={true} color="primary"    className="linkstyle"  style={{width:"60%"}}
                                    onClick={()=>{
                                        
                                        setUpdateid(da.id)
                                        setupdatename("Are you sure to Cancel your Approve the Interested Request with Profile '"+da.rname+" "+da.rsurname+"' ?")
                                        setOpenUpdateDailog(true);
                                    }}
                                    >
                                        Approve Request
                                    </Button>}     
                                </td>
                                <td className="text-center">{da.req_date}</td> 
                                    
                                
                            </tr>);
                        })}
                    </tbody>
                     
            </table>
               </div>
              <div
                role="tabpanel"
                hidden={value !== 1}
                className="text-center"
                >
                    <br/>
                    <table id="table_id" className="table table-striped">
                <thead>
                        <tr>
                        <th className="text-center">No</th>
                        <th className="text-center">For Profile</th>
                        <th className="text-center">Requested Profile</th>   
                        <th className="text-center">Requested Status</th>    
                        <th className="text-center">Requested Date</th>
                            
                        

                        </tr>
                    </thead>
                    <tbody>
                     
                     
                        {reqsbyu.length===0 &&<tr><td colSpan="5"><p className="text-center">You have not sent an <b>Interested Request</b> to any Profile....</p></td></tr>}
                        {reqsbyu.map((da,i)=>{
                            return (<tr>
                                <td>{reqsbyu.length-i}</td>
                                <td className="text-center">
                                    <img src={da.pdpic} className="img-thumbnail"  style={{height:"200px" }}/>
                                     
                                    <br/>
                                    Name: {da.pname} {da.surname}
                                    <br/>
                                    Gender: {da.pgender}
                                    <br/>
                                    Status: {da.pstatus===1?<span style={{color:"green"}}>Active</span>:<span style={{color:"red"}}>Waiting for Approval</span>}
                                </td>
                                <td className="text-center">
                                    <img src={da.rdpic} className="img-thumbnail"  style={{height:"200px" }}/>
                                     
                                    <br/>
                                    Name: {da.rname} {da.rsurname}
                                    <br/>
                                    Gender: {da.rgender}
                                    <br/>
                                    {da.reqstatus===1 && <span>
                                      Contact no: {da.rmob}
                                      <br/>
                                      Email-ID: {da.remail}  
                                      <br/>
                                    </span>}
                                    {da.rstatus===1 &&<Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/profile/"+da.rid} className="linkstyle"  style={{width:"60%"}}>View Profile</Button>}
                                </td>
                                <td className="text-center">
                                    <>{da.reqstatus===0?<span>Requested and Waiting for Approval...</span>:<span>Request Approved</span>}<br/><br/> </>    
                                    {da.reqstatus===0 && <Button variant="contained" fullWidth={true} color="primary"    className="linkstyle"  style={{width:"60%"}}
                                    onClick={()=>{
                                        
                                        setDeleteid(da.id)
                                        setDeletename("Are you sure to Cancel your Interested Request with Profile '"+da.rname+" "+da.rsurname+"' ?")
                                        setOpenDeleteDailog(true);
                                    }}
                                    >
                                        Cancel Request
                                    </Button>}  
                                </td>
                                <td className="text-center">{da.req_date}</td> 
                                    
                                
                            </tr>);
                        })}
                    </tbody>
                     
            </table>
               </div>     
               
             
            </Grid>
             
         </Grid>
         
          
        </Box>)}
        
         
    </div>);
}